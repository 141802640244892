import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

export async function getProgram(programId) {
  try {
    let result = await API.graphql(
      graphqlOperation(customQueries.programById, {
        id: programId,
      })
    );
    return result.data.getProgram;
  } catch (error) {
    console.log("Program fetch error: ", error);
    throw Exceptions.ProgramFetchFailure;
  }
}

export async function listPrograms(orgID) {
  const operation = graphqlOperation(queries.programsByOrganization, {
    sortDirection: "ASC",
    orgID: orgID,
    limit: 1000,
  });
  let programs;
  try {
    programs = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    console.log("Program fetch error: ", error);
    throw Exceptions.OrgProgramFetchFailure;
  }
  //Temporary archiving feature until full archiving is implemented
  return programs.filter((program) => !program.name.includes("Invalid - "));
}
