import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import { Field, ErrorMessage } from "formik";

const useStyles = makeStyles(
  {
    required: { color: "red" },
  },
  { name: "MuiFormHelperText" }
);

const FormikField = ({
  label,
  name,
  type = "text",
  required = false,
  disabled = false,
  ...rest
}) => {
  let isDisabled = false;
  if (disabled === true) {
    isDisabled = true;
  }

  const classes = useStyles();

  return (
    <Field
      {...rest}
      required={required}
      className={classes.required}
      autoComplete="off"
      as={TextField}
      label={label}
      fullWidth
      type={type}
      name={name}
      variant="standard"
      disabled={isDisabled}
      helperText={<ErrorMessage name={name} />}
    />
  );
};

export default FormikField;
