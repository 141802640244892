import { graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

/**
 * Returns all evaluation responses for the given userId
 * @param {*} userId
 */
export async function fetchEvalResponses(userId) {
  let operation = graphqlOperation(queries.listEvaluationResponses, {
    filter: { subjectId: { eq: userId } },
    limit: 100,
  });

  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.EvaluationFetchFailure;
  }

  return response;
}

/**
 * Returns all evaluation responses for the given userId in the format needed for table display
 * @param {*} userId
 */
export async function fetchEvalResponsesForTable(userId) {
  let operation = graphqlOperation(customQueries.evalsBySubject, {
    subjectId: userId,
    limit: 100,
  });

  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.EvaluationFetchFailure;
  }

  return response;
}
