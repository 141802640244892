import { pickBy } from 'lodash';
import { InviteUserErrors, NameTypes, PGYValues, ProgramRoles, ProgramRolesArray } from '../../../model/ApplicationConstants';

import {
  emailExists,
  npiExists,
} from "../../api/UserAPI";

export const npiRequired = (role) => {
  return role === ProgramRoles.User;
};

export const programRequired = (role) => {
  return role !== ProgramRoles.Admin;
};

export const validateName = (name, nameType) => {
  if(!nameType || (nameType !== NameTypes.First && nameType !== NameTypes.Last)){
    console.error(InviteUserErrors.NoNameType);
    return InviteUserErrors.NoNameType
  }
  if(!name || !name.trim()){
    if(nameType === NameTypes.First) {
      return InviteUserErrors.EmptyFirstName
    }
    return InviteUserErrors.EmptyLastName
  }
  if(name.length > 50){
    if(nameType === NameTypes.First) {
      return InviteUserErrors.FirstNameTooLong
    }
    return InviteUserErrors.LastNameTooLong
  }
  return;
}

export const validateEmail = async (email, testData, invitingExistingRater) => {
  if(!email){
    return InviteUserErrors.EmptyEmail;
  }
  if(email.length > 256){
    return InviteUserErrors.EmailTooLong;
  }
  // This is the regex check proposed by Wikipedia for checking email standards
  /* eslint-disable no-useless-escape */
  if(!(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(email))){
    return InviteUserErrors.ImproperlyFormattedEmail;
  }
  if(testData){
    if(testData.includes(email)){
      return InviteUserErrors.EmailInUse;
    }
  } else {
    const emailAlreadyExists = await emailExists(email);
    if(emailAlreadyExists && !invitingExistingRater){
      return InviteUserErrors.EmailInUse;
    }
  }
}

export const validateRole = (role) => {
  if(!role){
    return InviteUserErrors.NoRoleSelected;
  }
  if(!ProgramRolesArray.includes(role)){
    return InviteUserErrors.InvalidRole;
  }
}

export const validateProgram = (program, role) => {
  if(role && programRequired(role) && !program){
    return InviteUserErrors.NoProgramSelected;
  }
}

export const validateNpi = async (npi, role, testData) => {
  if(role && npiRequired(role) && !npi){
    return InviteUserErrors.NpiRequired;
  }
  if(npi){
    const convertedNpi = parseInt(npi);
    if(typeof convertedNpi !== 'number'){
      return InviteUserErrors.InvalidNpi;
    }
    if(convertedNpi < 1000000000 || convertedNpi > 1999999999) {
      return InviteUserErrors.InvalidNpi;
    }
    if(testData){
      if(testData.includes(convertedNpi)){
        return InviteUserErrors.NpiInUse;
      }
    } else {
      const npiAlreadyExits = await npiExists(convertedNpi);
      if(npiAlreadyExits){
        return InviteUserErrors.NpiInUse;
      }
    }
  }
}

export const validatePgy = (pgy) => {
  if(pgy && !PGYValues.includes(pgy)){
    return InviteUserErrors.PgyOutOfBounds;
  }
}

export const validateUserInvite = async ({userInvite, testEmailData, testNpiData, invitingExistingRater}) => {
  if(!userInvite || Object.keys(userInvite).length === 0){
    return InviteUserErrors.MissingData;
  }
  const { 
    email, 
    firstName, 
    lastName,
    role,
    program,
    npi,
    pgy,
  } = userInvite;
  const emailErrors = await validateEmail(email, testEmailData, invitingExistingRater)
  const firstNameErrors = validateName(firstName, NameTypes.First);
  const lastNameErrors = validateName(lastName, NameTypes.Last);
  const roleErrors = validateRole(role);
  const programErrors = validateProgram(program, role);
  const npiErrors = await validateNpi(npi, role, testNpiData);
  const pgyErrors = validatePgy(pgy);
  const errorObject = {
    email: emailErrors,
    firstName: firstNameErrors,
    lastName: lastNameErrors,
    role: roleErrors,
    program: programErrors,
    npi: npiErrors,
    pgy: pgyErrors,
  }
  const strippedErrorObject = pickBy(errorObject, error => error !== undefined);
  if(Object.keys(strippedErrorObject).length === 0){
    return;
  }
  return strippedErrorObject;
}