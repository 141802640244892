import { NurseReportFields } from "../../model/ApplicationConstants";

/**
 * Determine whether or not an object exists and has data
 *
 * @param {*} obj - Object to test
 * @returns - boolean
 */
export const objectIsEmpty = (obj) => {
  return (
    obj === undefined ||
    (Object.keys(obj).length === 0 && obj.constructor === Object)
  );
};

export const getEnvironmentURL = (env) => {
  switch (env) {
    case "prod":
      return NurseReportFields.prodRestUrl;
    case "dev":
      return NurseReportFields.developRestUrl;
    case "devsandbox":
      return NurseReportFields.devsandboxRestUrl;
    default:
      return NurseReportFields.prodRestUrl;
  }
};
