import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Typography } from "@mui/material";

import { getProgram } from "../../api/ProgramAPI";
import { ProgramProcedures } from "../procedures/ProgramProcedures";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

const Program = () => {
  const params = useParams();
  const classes = useStyles();
  const programId = params.programId;
  const [program, setProgram] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    async function fetchProgram() {
      let currentProgram;
      try {
        currentProgram = await getProgram(programId);
      } catch (exception) {
        console.log("Program fetch error: ", exception);
      }
      console.log("current program: ", currentProgram);
      setProgram(currentProgram);
    }

    if (programId === undefined) return;
    fetchProgram();
  }, [programId]);

  return (
    <React.Fragment>
      <div>
        <Paper className={classes.paper}>
          <Typography variant="h3">{program.name}</Typography>
          <Typography variant="subtitle1">{program.description}</Typography>
        </Paper>
        <Paper className={classes.paper}>
          <ProgramProcedures program={program} />
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default Program;
