import { API } from "aws-amplify";

/**
 * Automaticaly paginates a graphQL operation. Handles 'nextToken' logic for you
 * @param {*} operation - a graphqlOperation created by the aws-amplify lib.
 * @param {*} items - optional, used mainly for recursively building the array.
 */
export async function fetchOperationAutoPaginate(operation, items = []) {
  let response = await API.graphql(operation);
  let keys = Object.keys(response.data);
  if (keys.length === 1) {
    let responseProperty = keys[0];
    let retrievedItems = items.concat(response.data[responseProperty].items);

    let next = response.data[responseProperty].nextToken;
    if (next) {
      var op = { ...operation };
      op.variables.nextToken = next;
      return await fetchOperationAutoPaginate(op, retrievedItems);
    } else {
      return retrievedItems;
    }
  } else {
    throw new Error("Invalid response");
  }
}
