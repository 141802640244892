import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import MaterialTable from "@material-table/core";
import { Checkbox } from "@mui/material";

export const UserCertificationRecordTable = ({
  certificationRecords,
  setFilter,
  loading,
  handleCertClick,
  updatingCertStatus,
}) => {
  const [selectedRow, setSelectedRow] = useState();

  const handleRowClick = (certificationRecordId) => {
    if (certificationRecordId === selectedRow) {
      certificationRecordId = undefined;
    }
    setSelectedRow(certificationRecordId);
    setFilter(certificationRecordId);
  };

  const certRecordColumns = [
    { title: "Procedure", field: "procedureName" },
    { 
      title: "Certified", 
      field: "certified",
      render: (rowData) => {
        return(
          <Checkbox 
            disabled={updatingCertStatus}
            checked={rowData.certified} 
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              if(handleCertClick){
                handleCertClick(event.target.checked, rowData.certificationId);
              } else {
                console.log('no function passed to handle cert checkbox interaction')
              }
            }}
          />
        )
      }
    },
    { title: "Certification Method", field: "certMethod" },
    { title: "Unprepared/ Critical Deficiency", field: "unpreparedCritDef" },
    { title: "Inexperienced", field: "inexperienced" },
    { title: "Intermediate", field: "intermediate" },
    { title: "Practice Ready", field: "practiceReady" },
    { title: "Exceptional", field: "exceptional" },
    { title: "Total Evaluations", field: "totalEvaluations" },
  ]

  const preparedCertRecords = (certificationRecords && certificationRecords.length > 0) ?
  certificationRecords.map((record) => {
    return {
      procedureName: record.procedure.name,
      unpreparedCritDef: record.certificationLog.items.filter(response => response.ratingLevel === 1).length,
      inexperienced: record.certificationLog.items.filter(response => response.ratingLevel === 2).length,
      intermediate: record.certificationLog.items.filter(response => response.ratingLevel === 3).length,
      practiceReady: record.certificationLog.items.filter(response => response.ratingLevel === 4).length,
      exceptional: record.certificationLog.items.filter(response => response.ratingLevel === 5).length,
      totalEvaluations: record.totalProcedureEvals || 0,
      certMethod: record.certificationMethod || "N/A",
      certified: record.isCertified,
      certificationId: record.id,
    }
  }) : certificationRecords;

  return (
    <Paper>
      <MaterialTable
        isLoading={loading}
        title="Certification Records"
        columns={certRecordColumns}
        data={preparedCertRecords}
        onRowClick={(event, rowData) => {
          handleRowClick(rowData.certificationId)
        }}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData.certificationId ? "rgba(25, 118, 210, 0.08)" : "#FFF",
          })
        }}
      />
    </Paper>
  );
};
