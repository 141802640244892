import React, { useEffect, useReducer } from "react";
import "typeface-roboto";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useCookies } from "react-cookie";

import AdminHome from "./AdminHome";
import LoadingPage from "./LoadingPage";
import { Header } from "./login/Header";
import { Footer } from "./login/Footer";
import { SignInHeader } from "./login/SignInHeader";
import { SignInFooter } from "./login/SignInFooter";
import "./login/styles.css";
import { AppProvider } from "../AppContext";
import {
  fetchOrganizationData,
  fetchOrgs,
  fetchOrganization,
} from "./api/OrganizationAPI";
import { constructUserInfo, fetchCurrentUserEmail } from "./api/UserAPI";
import UserHome from "./UserHome";
import { DispatchActions } from "../model/ApplicationConstants";
import { I18n } from "aws-amplify";

// Overwrite error messages
I18n.putVocabularies({
  en: {
    "Incorrect username or password.":
      "Sorry, that password isn't right. Do you need to reset your password?",
    "User does not exist.":
      "Username does not exist. Please note: Usernames are case sensitive.",
  },
});

function ProtectedApp() {
  const initialState = {
    currentUser: {
      cognitoPermissions: {
        isSysAdmin: false,
        isAdmin: false,
        isProgramAdmin: false,
        isUser: false,
      },
      orgCookie: undefined,
    },
    organization: undefined,
  };

  const [cookies, setCookie] = useCookies(["sysAdminOrg"]);

  const reducer = (state, action) => {
    switch (action.type) {
      case DispatchActions.UpdateCurrentUser:
        return { ...state, currentUser: action.payload };
      case DispatchActions.UpdateOrganization:
        return { ...state, organization: action.payload };
      default:
        return state;
    }
  };

  function dispatchMiddleware(dispatch) {
    return async function middle(action) {
      switch (action.type) {
        case DispatchActions.FetchCurrentUser:
          const email = await fetchCurrentUserEmail();
          action.payload = await constructUserInfo(email);
          action.type = DispatchActions.UpdateCurrentUser;
          return dispatch(action);
        case DispatchActions.UpdateOrganization:
          action.payload = await fetchOrganizationData(currentUser);
          return dispatch(action);
        default:
          return dispatch(action);
      }
    };
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const middleware = dispatchMiddleware(dispatch);
  const { currentUser } = state;

  useEffect(() => {
    async function fetchUserInfo() {
      const email = await fetchCurrentUserEmail();
      const user = await constructUserInfo(email);
      // For sysadmin, check cookie to see which org they were in last
      if (user.cognitoPermissions.isSysAdmin) {
        let orgCookie = cookies["sysAdminOrg"];
        let orgLookup = orgCookie && (await fetchOrganization(orgCookie));
        if (orgLookup) {
          user.orgCookie = orgCookie;
        } else {
          let orgs = await fetchOrgs();
          user.orgCookie = orgs[0].id;
          setCookie(user.orgCookie);
        }
      }
      if (
        user.cognitoPermissions.isSysAdmin ||
        user.cognitoPermissions.isAdmin ||
        user.cognitoPermissions.isProgramAdmin ||
        user.cognitoPermissions.isUser
      ) {
        user.lastLogin = new Date().toISOString();
        middleware({ type: DispatchActions.UpdateCurrentUser, payload: user });
      }
    }
    fetchUserInfo();
  }, []);

  useEffect(() => {
    async function fetchOrgInfo() {
      const org = await fetchOrganizationData(currentUser);
      middleware({ type: DispatchActions.UpdateOrganization, payload: org });
    }
    fetchOrgInfo();
  }, [currentUser]);

  return currentUser.cognitoPermissions.isSysAdmin ||
    currentUser.cognitoPermissions.isAdmin ||
    currentUser.cognitoPermissions.isProgramAdmin ||
    currentUser.cognitoPermissions.isUser ? (
    <AppProvider value={{ state: state, dispatch: middleware }}>
      {((currentUser.cognitoPermissions.isSysAdmin ||
        currentUser.cognitoPermissions.isAdmin) && <AdminHome />) ||
        (currentUser.cognitoPermissions.isProgramAdmin && (
          <AdminHome /> //placeholder for ProgramAdminHome
        )) || <UserHome />}
    </AppProvider>
  ) : (
    <LoadingPage />
  );
}

export default withAuthenticator(ProtectedApp, {
  hideSignUp: true,
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter,
    },
    Footer,
  },
});
