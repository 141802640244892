import React, { useState, useEffect, useContext } from "react";
import { useCurrentUser } from "../../model/Users";
import { updateUserSettings } from "../api/UserAPI";
import TextField from "@mui/material/TextField";
import { Paper, Typography } from "@mui/material/";
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AppContext from "../../AppContext";
import { DispatchActions } from "../../model/ApplicationConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& > *": {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: theme.spacing(50),
    },
  },
  heading: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(1),
  },
}));

export const Profile = () => {
  const currentUser = useCurrentUser();
  const appState = useContext(AppContext);
  const { dispatch } = appState;
  const classes = useStyles();
  const defaultSettings = { receiveWeeklyNewEvaluationsEmail: true };

  const [settings, setSetting] = useState(defaultSettings);
  const [showProgramAdminSettings, setShowProgramAdminSettings] =
    useState(false);

  const handleChange = (event) => {
    const newSettings = {
      ...settings,
      [event.target.name]: event.target.checked,
    };

    try {
      updateUserSettings(currentUser.id, newSettings);
      setSetting(newSettings);
      dispatch({ type: DispatchActions.FetchCurrentUser });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function initializeUserSettings() {
      const settings = currentUser.settings
        ? currentUser.settings
        : defaultSettings;

      setSetting(settings);

      if (currentUser.cognitoPermissions) {
        setShowProgramAdminSettings(
          currentUser.cognitoPermissions.isProgramAdmin
        );
      }
    }

    initializeUserSettings();
  }, [currentUser]);

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography className={classes.heading} variant="h4">
          User Profile
        </Typography>
        <div className={classes.root}>
          <TextField
            label="First Name"
            value={currentUser.firstName}
            disabled
            variant="outlined"
          />{" "}
          <br />
          <TextField
            label="Last Name"
            value={currentUser.lastName}
            disabled
            variant="outlined"
          />{" "}
          <br />
          <TextField
            label="Email"
            value={currentUser.email}
            disabled
            variant="outlined"
          />{" "}
          <br />
          <TextField
            label="Username"
            value={currentUser.userName}
            disabled
            variant="outlined"
          />
        </div>
      </Paper>
      {showProgramAdminSettings && (
        <Paper className={classes.root}>
          <Typography className={classes.heading} variant="h4">
            User Settings
          </Typography>
          <FormControlLabel
            className={classes.heading}
            control={
              <Switch
                checked={settings && settings.receiveWeeklyNewEvaluationsEmail}
                onChange={handleChange}
                name="receiveWeeklyNewEvaluationsEmail"
              />
            }
            label="Receive weekly new evaluations email"
            color="primary"
          />
        </Paper>
      )}
    </div>
  );
};
