import { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";

export function useCurrentOrganization() {
  const appState = useContext(AppContext);
  const [currOrganization, setCurrOrganization] = useState({});

  const {
    state: { organization },
  } = appState;

  useEffect(() => {
    setCurrOrganization(organization);
  }, [organization]);

  return currOrganization;
}
