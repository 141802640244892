/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://p2v461v5db.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "nurseReport",
            "endpoint": "https://yck6pch5gl.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://rro2cnht6zhw7csc5wlksqgara.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-du7cda3gb5buza5i3aqd5chapq",
    "aws_cognito_identity_pool_id": "us-east-1:41a05627-25dd-4dca-a04b-cb2aaf00287b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Kzgi6icUU",
    "aws_user_pools_web_client_id": "2unp45mj4ra8lh9f6r56b0ujqd",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bedside-20200204102939-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://bedside-20200204102939-hostingbucket-prod.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "bedsidecontent81932-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
