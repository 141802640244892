import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { ProgramTable } from "./ProgramTable";
import { ProgramForm } from "./ProgramForm";
import makeStyles from '@mui/styles/makeStyles';
import { Button, Dialog, DialogTitle, Typography } from "@mui/material";

import { listPrograms } from "../../../components/api/ProgramAPI";
import { useCurrentOrganization } from "../../../model/Organization";
import { useCurrentUser } from "../../../model/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export const Programs = () => {
  const organization = useCurrentOrganization();
  const currentUser = useCurrentUser();
  const classes = useStyles();
  const [newProgramFormOpen, setNewProgramFormOpen] = useState(false);

  const [programs, setPrograms] = useState();

  useEffect(() => {
    async function fetchPrograms() {
      let currentPrograms;
      try {
        currentPrograms = await listPrograms(organization.id);
      } catch (exception) {
        console.log("Program fetch error: ", exception);
      }
      setPrograms(currentPrograms);
    }

    if (organization === undefined || Object.keys(organization).length === 0)
      return;
    fetchPrograms();
  }, [newProgramFormOpen, organization]);

  const handleNewProgramFormOpen = () => {
    setNewProgramFormOpen(true);
  };

  const handleNewProgramFormClose = () => {
    setNewProgramFormOpen(false);
  };

  const allowAddProgram = () => {
    return (
      currentUser.cognitoPermissions && currentUser.cognitoPermissions.isAdmin
    );
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography>{organization && organization.title}</Typography>
        {allowAddProgram() && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewProgramFormOpen}
          >
            Create Program
          </Button>
        )}

        <Dialog
          open={newProgramFormOpen}
          onClose={handleNewProgramFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Program</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter new program information</DialogContentText>
            <ProgramForm
              closeForm={handleNewProgramFormClose}
              orgID={organization && organization.id}
            ></ProgramForm>
          </DialogContent>
        </Dialog>
      </div>
      {programs && programs.length > 0 && <ProgramTable programs={programs} />}
    </React.Fragment>
  );
};
