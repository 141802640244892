import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as customQueries from "../../graphql/customQueries";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

/**
 * List the Procedures associated with a particular Program
 *
 * @param {*} programId - Program to list procedures for
 * @returns - A list of procedures for the programId provided
 */
export async function listProgramProcedures(programId) {
  const operation = graphqlOperation(customQueries.programProceduresByProgram, {
    programId: programId,
    limit: 100,
  });
  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.ProgramProcedureFetchFailure;
  }
  const sorted = response.sort((p1, p2) => {
    return p1.procedure.name > p2.procedure.name ? 1 : -1;
  });
  return sorted;
}

/**
 * Create a new ProgramProcedure with the following params.
 * @param {*} procedureId - The id of the procedure to add to the program
 * @param {*} programId - The program to add the procedure to
 */
export async function newProgramProcedure(procedureId, programId) {
  let exists;
  try {
    exists = await programProcedureExists(procedureId, programId);
  } catch (error) {
    console.log("Program Procedure Lookup Failure: ", error);
    throw Exceptions.ProgramProcedureLookupFailure;
  }
  let response;
  if (!Boolean(exists)) {
    response = await API.graphql(
      graphqlOperation(mutations.createProgramProcedure, {
        input: {
          procedureId,
          programId,
        },
      })
    );
  } else {
    throw Exceptions.ProgramProcedureExists;
  }
  return response.data.createProgramProcedure;
}

/**
 * Determines if a ProgramProcedure association exists for the provided Program and Procedure combination
 * @param {*} programId
 * @param {*} procedureId
 */
export async function programProcedureExists(procedureId, programId) {
  let params = {
    filter: { programId: { eq: programId }, procedureId: { eq: procedureId } },
  };
  let response = await API.graphql(
    graphqlOperation(queries.listProgramProcedures, params)
  );
  return response.data.listProgramProcedures.items.length > 0;
}

export async function removeProgramProcedure(id) {
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteProgramProcedure, {
        input: {
          id,
        },
      })
    );
  } catch (error) {
    console.log("Program Procedure Delete Failure: ", error);
    throw Exceptions.ProgramProcedureDeleteFailure;
  }
}
