import { graphqlOperation, API } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import { Exceptions } from "../../model/ApplicationConstants";
import { createUser, updateUser } from "./UserAPI";
import { createMembership } from "./MembershipAPI";
import { fetchOperationAutoPaginate } from "./APIUtil";
import {
  ProgramRoles,
  UserPermissions,
} from "../../model/ApplicationConstants";
import {
  fetchInviteStatusForInviteRouter,
  invitationByEmail,
  listInvitations,
} from "../../graphql/customQueries";

class CreateUserInvitationError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = Exceptions.UserCreationFailure;
  }
}

export const InvitationStatus = {
  NoInvite: "NoInvite",
  Started: "Started",
  Notified: "Notified",
  Resolved: "Resolved",
};

export async function fetchInvitations(email, excludeStatus = "Resolved") {
  const emailVariables = email
    ? { email, invitationStatus: excludeStatus }
    : null;
  const query = emailVariables ? invitationByEmail : listInvitations;
  const operation = {
    query,
    variables: { ...emailVariables, limit: 100 },
    authMode: "API_KEY",
  };
  const invitations = await fetchOperationAutoPaginate(operation);
  return invitations;
}

async function createInviteForUser(user, programAdminId, role) {
  //TODO: When program role and user permission are disambiguated,
  //      we'll need to translate from one to the other.
  let permission;
  switch (role) {
    case ProgramRoles.Admin:
      permission = UserPermissions.Admins;
      break;
    case ProgramRoles.ProgramAdmin:
      permission = UserPermissions.ProgramAdmins;
      break;
    case ProgramRoles.User:
    default:
      permission = UserPermissions.Users;
  }
  const invite = {
    inviteeId: user.id,
    invitationStatus: "Started",
    email: user.email,
    orgID: user.orgID,
    programAdminId,
    permission,
  };
  const invitation = await API.graphql(
    graphqlOperation(mutations.newUserInvitation, { userInvitation: invite })
  );
  return invitation;
}

// Strip irrelevant values for Admin role.
function removeIrrelevantValuesForRole(role, user) {
  if (role === ProgramRoles.Admin) {
    return { ...user, program: "", pgy: "", isRater: false };
  } else {
    return user;
  }
}

function renameUserID(user) {
  let id = user.userID;
  delete user.userID;
  return { ...user, id };
}

const objectWithoutKey = (object, key) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

export async function createInvitation({
  program,
  programAdminId,
  role,
  ...userValues
}) {
  var finalUserValues = removeIrrelevantValuesForRole(role, userValues);

  try {
    var user = finalUserValues;

    if (user.userID) {
      console.log("updating user:", user);
      finalUserValues = renameUserID(finalUserValues);
      user = await updateUser(finalUserValues);
    } else {
      finalUserValues = objectWithoutKey(finalUserValues, "userID");
      console.log("creating User with values", finalUserValues);
      user = await createUser(finalUserValues);
    }
    if (program !== "") {
      await createMembership(user.id, program, role);
    }

    await createInviteForUser(user, programAdminId, role);

    return user.id;
  } catch (error)
   {
    console.log(error);
    throw new CreateUserInvitationError(
      "New user invitation couldn't be created"
    );
  }
}

export const getInviteByEmail = async (email) => {
  const invitationData = await API.graphql({
    query: fetchInviteStatusForInviteRouter,
    variables: { email: email }
  })

  return invitationData;
}