import React, { useState, useEffect, useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikField from "../shared/FormikField";
import FormikSelect from "../shared/FormikSelect";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

import {
  fetchUserAndPrograms,
  autoCapitalizeString,
} from "../../util/UserUtil";
import AppContext from "../../../AppContext";
import ProgramSelector from "./ProgramSelector";
import { PGYs } from "../../../model/ApplicationConstants";
import {
  editUser,
  constructUserInfo,
  fetchCurrentUserEmail,
} from "../../api/UserAPI";
import { getInviteByEmail, InvitationStatus } from "../../api/InvitationAPI";

import { DispatchActions } from "../../../model/ApplicationConstants";
import { usePrograms } from "../../../model/Programs";
import { useCurrentOrganization } from "../../../model/Organization";
import { useCurrentUser } from "../../../model/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  columns: {
    "& > *": {
      padding: theme.spacing(2),
    },
  },
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
}));

const EditUserSchema = Yup.object({
  firstName: Yup.string().required("Required").min(1),
  lastName: Yup.string().required("Required").min(1),
  npi: Yup.number()
    .nullable(true)
    .min(1000000000, "NPI must be 10 digits.")
    .max(1999999999, "NPI must be 10 digits."),
  programs: Yup.array().min(1),
});

const UserForm = () => {
  const params = useParams();
  const userId = params.userID;
  const classes = useStyles();
  const navigate = useNavigate();
  const appState = useContext(AppContext);
  const organization = useCurrentOrganization();
  const currentUser = useCurrentUser();
  const { dispatch } = appState;
  const programs = usePrograms();
  const [validPrograms, setValidPrograms] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [hasAccount, setHasAccount] = useState(true);
  const [invitationStatus, setInvitationStatus] = useState();
  const [userModel, setUserModel] = useState({
    id: "",
    userName: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    npi: "",
    pgy: "",
    orgID: "",
    programs: [
      {
        role: "",
        program: {
          name: "",
          id: "",
        },
      },
    ],
  });

  const [showInviteButton, setShowInviteButton] = useState(false);

  useEffect(() => {
    setShowInviteButton(
      invitationStatus === InvitationStatus.NoInvite && !hasAccount
    );
  }, [invitationStatus, hasAccount]);

  const handleCancelClick = (event) => {
    navigate(-1);
  };

  const inviteUser = (event) => {
    navigate(`/users/invite/${userModel.id}`);
  };

  useEffect(() => {
    async function getUserData(userId) {
      try {
        let user = await fetchUserAndPrograms(userId);
        setUserModel(user);
        if(user?.email){
          const inviteData = await getInviteByEmail(user.email);
          const newInviteStatus = inviteData?.data?.invitationsByEmail?.items?.[0]?.invitationStatus;
          setInvitationStatus(newInviteStatus ? newInviteStatus : InvitationStatus.NoInvite);
        }
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
      } catch (error) { 
        console.error(error);
      }
    }
    getUserData(userId);
  }, [userId]);

  useEffect(() => {
    let hasAccount = userModel.userName;
    setHasAccount(hasAccount);
  }, [userModel]);

  useEffect(() => {
    const programSelectItems = programs.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
    const sortedProgramSelectItems = programSelectItems.sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    });
    setValidPrograms(sortedProgramSelectItems);
  }, [programs]);

  async function onSubmit(values) {
    var status = undefined;
    values.orgID = organization.id;
    try {
      status = await editUser(values);
      if (status === "failure") {
        setEditOpen(true);
        return;
      }
    } catch (error) {
      console.error("User update failed: ", error);
      setEditOpen(true);
      return;
    }
    // If current user is being edited, update the app context to reflect updates
    if (currentUser.id === userId) {
      const email = await fetchCurrentUserEmail();
      const user = await constructUserInfo(email);
      dispatch({
        type: DispatchActions.UpdateCurrentUser,
        payload: { ...currentUser, memberships: user.memberships },
      });
    }
    dispatch({ type: DispatchActions.UpdateOrganization });
    navigate(-1);
  }

  const handleEditClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEditOpen(false);
  };

  const onChange = (event, values) => {
    let newString;
    if (event.target.name === "email") {
      newString = event.target.value.toLowerCase();
      setEmail(newString);
      values.email = newString;
    } else {
      newString = autoCapitalizeString(event.target.value);
      if (event.target.name === "firstName") {
        setFirstName(newString);
        values.firstName = newString;
      } else if (event.target.name === "lastName") {
        setLastName(newString);
        values.lastName = newString;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={userModel}
      validationSchema={EditUserSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, values, isSubmitting }) => {
        return (
          <Form>
            <Grid container className={classes.root} spacing={3}>
              <Grid item className={classes.columns} xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Account
                  </Typography>
                  <Divider />
                  <div className={classes.fields}>
                    <FormikField
                      name="userName"
                      label="username"
                      disabled={true}
                      required={false}
                    />
                    <Field
                      autoComplete="off"
                      as={TextField}
                      fullWidth
                      type="email"
                      disabled={true}
                      required={false}
                      helperText={<ErrorMessage name="email" />}
                      name="email"
                      label="email"
                      variant="standard"
                      value={email}
                      onChange={(e) => {
                        onChange(e, values);
                      }}
                    />
                    <FormikField
                      name="password"
                      label="password"
                      type="password"
                      disabled={true}
                      required={false}
                    />
                  </div>
                  <Typography variant="subtitle2" color="textSecondary">
                    Profile
                  </Typography>
                  <Divider />
                  <div className={classes.fields}>
                    <Field
                      autoComplete="off"
                      as={TextField}
                      fullWidth
                      type="text"
                      disabled={false}
                      required={true}
                      helperText={<ErrorMessage name="firstName" />}
                      name="firstName"
                      label="first name"
                      variant="standard"
                      value={firstName}
                      onChange={(e) => {
                        onChange(e, values);
                      }}
                    />
                    <Field
                      autoComplete="off"
                      as={TextField}
                      fullWidth
                      type="text"
                      disabled={false}
                      required={true}
                      helperText={<ErrorMessage name="lastName" />}
                      name="lastName"
                      label="last name"
                      variant="standard"
                      value={lastName}
                      onChange={(e) => {
                        onChange(e, values);
                      }}
                    />
                    <FormikField name="npi" label="npi" type="number" />
                    <FormikSelect
                      name="pgy"
                      label="pgy"
                      items={PGYs}
                      value={values.pgy}
                    />
                  </div>
                </Paper>
              </Grid>

              <Grid item className={classes.columns} xs={12} sm={6}>
                <Paper className={classes.paper}>
                  {showInviteButton && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      startIcon={<ArrowUpwardIcon />}
                      onClick={inviteUser}
                    >
                      Upgrade Rater to User
                    </Button>
                  )}

                  <Typography variant="subtitle2" color="textSecondary">
                    Programs
                  </Typography>
                  <Divider />
                  <ProgramSelector
                    values={values}
                    validPrograms={validPrograms}
                  />
                </Paper>
              </Grid>

              <Grid item className={classes.buttonContainer} xs={12}>
                <Button
                  variant="contained"
                  onClick={handleCancelClick}
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!dirty || !isValid || isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Snackbar
              open={editOpen}
              autoHideDuration={6000}
              onClose={handleEditClose}
            >
              <Alert onClose={handleEditClose} severity="error">
                User Update has failed. Please try again later.
              </Alert>
            </Snackbar>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
