import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useParams, Link } from "react-router-dom";

import {
  Autocomplete,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

import { NurseReportFields } from "../../../model/ApplicationConstants";
import { getEnvironmentURL } from "../../util/Utility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  paperMain: {
    width: "100%",
    padding: theme.spacing(4),
    "& .MuiAutocomplete-popper": {
      marginTop: theme.spacing(7),
    },
  },
  paperData: {
    border: "1px solid black",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
  autocomplete: {
    width: "300px",
    paddingBottom: theme.spacing(2),
    border: "2px solid lightgrey",
    borderRadius: "10px",
    padding: "5px 20px 5px 20px",
  },
  headerlogo: {
    maxWidth: "150px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  footerlogo: {
    maxWidth: "60px",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
    boxShadow: "none",
    justifyText: "center",
    fontSize: "16px",
  },
  linkText: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const options = [{ label: "", id: "" }];

const UserCertifiedProcedureReport = () => {
  const classes = useStyles();
  const params = useParams();
  const orgId = params.orgId;
  const [orgName, setOrgName] = useState("");
  const [value, setValue] = React.useState(options[0]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [certifiedProcedures, setCertifiedProcedures] = useState([]);
  const [programProcedures, setProgramProcedures] = useState([]);
  const [programProcedureDisplay, setProgramProcedureDisplay] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);

  var path = process.env.PUBLIC_URL;
  const siteUrl = getEnvironmentURL(process.env.REACT_APP_ENV);
  var bedsideLogo = "/simplbedsidelogo1.png";
  var simplLogo = "/simpllogonotext.png";

  useEffect(() => {
    fetch(
      //`${NurseReportFields.devsandboxRestUrl}/${orgId}` //DEVSANDBOX
      //`${NurseReportFields.developRestUrl}/${orgId}` //DEVELOP
      //`${NurseReportFields.prodRestUrl}/${orgId}` //PROD
      `${siteUrl}/${orgId}`
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.success === "ORG_USER_LIST_CREATED") {
            setUsers(data.users);
            setOrgName(data.orgName);
            setUnauthorized(false);
          } else if (
            data.success === "IP_OUT_OF_RANGE" ||
            data.success === "INVALID_ORGANIZATION_ID"
          ) {
            setUnauthorized(true);
          }
        },
        (error) => {
          setUnauthorized(true);
        }
      )
      .catch(function (error) {
        console.log(error);
        setUnauthorized(true);
      });
  }, [orgId]);

  useEffect(() => {
    if (value && value.id !== "") {
      setProgramProcedureDisplay([]);
      setProgramProcedures([]);

      //set selected user by filtering user list
      let currentSelection = users.filter((user) => user.id === value.id);
      fetch(
        //`${NurseReportFields.devsandboxRestUrl}/${orgId}/${value.id}` //DEVSANDBOX
        //`${NurseReportFields.developRestUrl}/${orgId}/${value.id}` //DEVELOP
        //`${NurseReportFields.prodRestUrl}/${orgId}/${value.id}` //PROD
        `${siteUrl}/${orgId}/${value.id}`
      )
        .then((res) => res.json())
        .then(
          (data) => {
            // Returns certified procedures and full program procedure list
            setCertifiedProcedures(data.certifiedProcedures);
            setSelectedUser(currentSelection[0]);
            setProgramProcedures(data.programProcedures);
          },
          (error) => {}
        );
    }
  }, [value, orgId, users]);

  useEffect(() => {
    if (
      programProcedures &&
      programProcedures.length > 0 &&
      selectedUser.memberships.items.length > 0 &&
      programProcedureDisplay.length === 0
    ) {
      let currentMemberships = selectedUser.memberships.items;
      let parsedData = [];
      // Iterate through memberships, and populate program procedure data for display
      for (var i = 0; i < currentMemberships.length; i++) {
        // Data model for program item
        var program = {
          programId: currentMemberships[i].program.id,
          programName: currentMemberships[i].program.name,
          certifiedProcedures: [],
          uncertifiedProcedures: [],
        };
        // Filter procedures that are associated with the current membership.program
        let filteredProcs = programProcedures.filter(
          (pp) => pp.programId === currentMemberships[i].program.id
        );
        // Parse the certified procedures for the current membership.program
        let certifiedProgProcs = certifiedProcedures
          .map((cp) => {
            if (
              filteredProcs.filter(
                (proc) => proc.procedureId === cp.procedureId
              ).length > 0
            ) {
              return { id: cp.procedureId, name: cp.procedureName };
            } else return undefined;
          })
          .filter((cp) => cp !== undefined)
          .sort((cp1, cp2) => {
            return cp1.name > cp2.name ? 1 : -1;
          });
        program.certifiedProcedures = certifiedProgProcs;

        // Parse the uncertified procedures for the current membership.program
        let uncertifiedProgProcs = filteredProcs
          .map((fp) => {
            if (
              certifiedProcedures.filter(
                (cp) => cp.procedureId === fp.procedure.id
              ).length === 0
            ) {
              return { id: fp.procedure.id, name: fp.procedure.name };
            } else return undefined;
          })
          .filter((fp) => fp !== undefined)
          .sort((fp1, fp2) => {
            return fp1.name > fp2.name ? 1 : -1;
          });
        program.uncertifiedProcedures = uncertifiedProgProcs;
        parsedData[i] = program; // Append the program data to the display data json array
      }

      // Now iterate through procedures and catch those not associated with a membership program
      //   Add those items to a "catchall" program and display
      let orphanProgram = mapOrphanedCertifications();
      if (orphanProgram) {
        parsedData[parsedData.length] = orphanProgram;
      }
      setProgramProcedureDisplay([...parsedData]);
    }
  }, [programProcedures, selectedUser, certifiedProcedures]);

  function mapOrphanedCertifications() {
    console.log("Getting here");
    // Data model for program item (assign name and 0 id to program)
    var program = {
      programId: "0",
      programName: "Optional Procedure Certifications",
      certifiedProcedures: [],
      uncertifiedProcedures: [],
    };

    // Only return certifications that are not assigned procedures if any exist, otherwise return null
    let certifiedOrphans = certifiedProcedures
      .map((cp) => {
        if (
          programProcedures.filter((pp) => pp.procedureId === cp.procedureId)
            .length === 0
        ) {
          return { id: cp.procedureId, name: cp.procedureName };
        } else return undefined;
      })
      .filter((cp) => cp !== undefined)
      .sort((cp1, cp2) => {
        return cp1.name > cp2.name ? 1 : -1;
      });
    console.log("Certified Orphans: ", certifiedOrphans);
    if (certifiedOrphans.length > 0) {
      program.certifiedProcedures = certifiedOrphans;
      return program;
    } else return null;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paperMain}>
        <Toolbar className={classes.header}>
          <img
            src={path + bedsideLogo}
            alt="logo"
            className={classes.headerlogo}
          />
          <Grid container direction="column" alignItems="flex-end">
            <Link className={classes.link} to="../users/home">
              {NurseReportFields.userHomeLinkText}
            </Link>
            <ListItem
              key="Email"
              component="a"
              href="mailto:help@simpl-bedside.org?subject=SIMPL Bedside Certification Report Support"
            >
              <ListItemText className={classes.linkText}>
                Contact Help & Support
              </ListItemText>
            </ListItem>
          </Grid>
        </Toolbar>{" "}
        <Typography
          variant="h4"
          color="textPrimary"
          align="center"
          className={classes.fields}
        >
          {NurseReportFields.reportTitle1}
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          align="center"
          className={classes.fields}
        >
          {NurseReportFields.reportTitle2}
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          align="center"
          className={classes.fields}
        >
          {orgName} Trainees
        </Typography>
        {!unauthorized ? (
          <Box display="flex" justifyContent="center">
            <Autocomplete
              disablePortal
              options={users}
              getOptionLabel={(option) => option.name || ""}
              defaultValue={{ label: "", id: "" }}
              value={value}
              className={classes.autocomplete}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Find a Trainee"
                />
              )}
            />
          </Box>
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            align="center"
            className={classes.fields}
          >
            You are not authorized to view this page.
          </Typography>
        )}
        <p />
        <Box px={40}>
          <Paper className={classes.paperData}>
            {!unauthorized &&
              programProcedureDisplay &&
              programProcedureDisplay.map((program) => {
                return (
                  <div>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      align="center"
                      className={classes.fields}
                    >
                      {program.programName}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      align="center"
                      className={classes.fields}
                    >
                      Certified Procedures
                    </Typography>
                    {program.certifiedProcedures.length > 0 ? (
                      <List px={5}>
                        {program.certifiedProcedures.map((certifiedProc) => {
                          return (
                            <ListItem key={certifiedProc.id} dense button>
                              <WatchLaterIcon style={{ color: "lime" }} />
                              <ListItemText
                                style={{ paddingLeft: "15px" }}
                                primary={certifiedProc.name}
                              ></ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    ) : (
                      <Typography
                        variant="h7"
                        color="textSecondary"
                        align="center"
                        className={classes.fields}
                      >
                        None
                      </Typography>
                    )}
                    {program.programId !== "0" && (
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="center"
                        className={classes.fields}
                      >
                        Uncertified Procedures
                      </Typography>
                    )}

                    {program.programId !== "0" &&
                      (program.uncertifiedProcedures.length > 0 ? (
                        <List>
                          {program.uncertifiedProcedures.map(
                            (uncertifiedProc) => {
                              return (
                                <ListItem key={uncertifiedProc.id} dense button>
                                  <AccessTimeOutlinedIcon
                                    style={{ color: "yellow" }}
                                  />
                                  <ListItemText
                                    style={{ paddingLeft: "15px" }}
                                    primary={uncertifiedProc.name}
                                  ></ListItemText>
                                </ListItem>
                              );
                            }
                          )}
                        </List>
                      ) : (
                        <Typography
                          variant="h7"
                          color="textSecondary"
                          align="center"
                          className={classes.fields}
                        >
                          None
                        </Typography>
                      ))}
                  </div>
                );
              })}
          </Paper>
        </Box>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          align="center"
          className={classes.fields}
        >
          {NurseReportFields.reportInstructions}
        </Typography>
        <Toolbar className={classes.footer}>
          <img
            src={path + simplLogo}
            alt="logo"
            className={classes.footerlogo}
          />
          <Link
            className={classes.link}
            to={{ pathname: "http://simpl.org" }}
            target="_blank"
          >
            {NurseReportFields.simplLinkText}
          </Link>
        </Toolbar>
      </Paper>
    </div>
  );
};

export default UserCertifiedProcedureReport;
