import React, { useEffect, useRef, useState } from "react";

import MaterialTable from "@material-table/core";
import { Paper, Dialog } from "@mui/material/";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';

import User from "./User";
import { fetchInvitations, InvitationStatus } from "../../api/InvitationAPI";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  links: {
    textDecoration: "none",
    color: "inherit",
  },
  dialogCloseIcon: {
    float: "right",
  },
  dialogTitleText: {
    float: "left",
  },
}));

export const returnLastLoginResult = (user, invitationStatus) => {
  if(user?.username){
    if(user.lastLogin){
      const lastLoginObj = new Date(user.lastLogin);
      const formattedDateString = moment(lastLoginObj).format('MMMM Do, YYYY [at] h:mm a');
      return formattedDateString;
    } else {
      return "Unknown"
    }
  } else if([InvitationStatus.Started, InvitationStatus.Notified].includes(invitationStatus)){
    return "Unregistered"
  }
  return "N/A";
}

const getUserInviteStatus = async (user) => {
  let inviteStatus;
  if(!user.username){
    let inviteResults = await fetchInvitations(user.email);
    inviteStatus = (inviteResults && inviteResults.length > 0) ? inviteResults[0].invitationStatus : undefined
  }
  return inviteStatus;
}

export const UserTable = ({ users, isAllPrograms = true, tablePrereqsLoaded }) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [userViewOpen, setUserViewOpen] = useState(false);
  const [preparedTableData, setPreparedTableData] = useState([]);
  const tableRef = useRef();

  const handleCloseUserView = async () => {
    setUserViewOpen(false);
  };

  useEffect(() => {
    setLoadingUserData(true);
    const prepareTableData = async () => {
      try {
        let results;
        if(users && users.length > 0){
          // Asynchronously loop through each user (this is needed because we may have to
          // fetch the user's invite status if they don't have a username)
          results = await Promise.all(users.map(async user => {
            if(!user){
              return
            }
            //const inviteStatus = await getUserInviteStatus(user);
            return {
              ...user,
              programString: user.programs,
              //lastLogin: returnLastLoginResult(user, inviteStatus),
            }
          }))
        }
        setPreparedTableData(results);
        setLoadingUserData(false);
        console.log('setting the prepared table data:', preparedTableData);
      } catch (error) {
        console.error(error);
      }
    }
    prepareTableData();
  }, [users])

  // This will clear the filter fields upon navigating/teardown.
  useEffect(() => {
    return () => {
      if(!tableRef.current){
        return
      }
      tableRef.current.dataManager.columns.map(item => {
        tableRef.current.onFilterChange(item.tableData.id, '');
      })
    }
  }, [])

  let columnDefinitionProgram = [
    { title: "Name", field: "name" },
    { title: "Programs", field: "programString" },
    { title: "Username", field: "username" },
    { title: "PGY", field: "pgy", defaultSort: "asc" },
    { title: "Program Admin", field: "isAdmin", type: "boolean" },
    //{ title: "Last Login", field: "lastLogin"},
  ];
  
  let columnDefinitionAllPrograms = [
    { title: "Name", field: "name" },
    { title: "Programs", field: "programString" },
    { title: "Username", field: "username" },
    { title: "PGY", field: "pgy", defaultSort: "asc" },
    //{ title: "Last Login", field: "lastLogin"},
  ];

  return (
    <div>
      <Paper>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <MaterialTable
          tableRef={tableRef}
          isLoading={loadingUserData || !tablePrereqsLoaded}
          title="User List"
          columns={
            isAllPrograms
              ? columnDefinitionAllPrograms
              : columnDefinitionProgram
          }
          data={preparedTableData}
          options={{
            filtering: true,
            pageSize: 10,
          }}
          onRowClick={(evt, rowData) => {
            setUser(rowData);
            setUserViewOpen(true);
          }}
        />
      </Paper>
      <Dialog
        open={userViewOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitleText}>User Details</div>
          <div className={classes.dialogCloseIcon}>
            <IconButton aria-label="close dialog" onClick={handleCloseUserView} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <User initialUser={user} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
