import React from "react";
import { useNavigate } from "react-router-dom";
import UsersHome from "./UsersHome";
import User from "./User";
import UserForm from "./UserForm";
import InviteUserRouter from "./InviteUser";
import UsersBulkInvite from "./UsersBulkInvite";
import UsersBulkInviteFileUpload from "./UsersBulkInviteFileUpload";

import { usePrograms } from "../../../model/Programs";

import { Routes, Route } from "react-router-dom";

export const Users = () => {
  const navigate = useNavigate();
  const programs = usePrograms();

  const onNewInviteCompleted = (newUserId) => {
    navigate(`view/${newUserId}`);
  };

  return (
    <Routes>
      <Route path={"home"} element={<UsersHome />}>
        <Route path={":programId"} element={<UsersHome />} />
      </Route>
      <Route path={"view/:userID"} element={<User />} />
      <Route path={"new"} element={<UserForm />} />
      {/* 
        Dev Note - As far as I could tell, having the invite/:userID route nested insite the invite
        didn't/shouldn't have worked. If pulling this out in this manner doesn't make sense, lets
        discuss what the approach should be.
       */}
      <Route
        exact
        path={"invite"}        
        element={
          <InviteUserRouter
            programs={programs}
            onCompletion={onNewInviteCompleted}
          />
        }
      />
      <Route
        path={"invite/:userID"}
        element={
          <InviteUserRouter
            programs={programs}
            onCompletion={onNewInviteCompleted}
          />
        }
      />
      <Route path={"bulkfileupload"} element={<UsersBulkInviteFileUpload />} />
      <Route path={"bulkuserinvite"} element={<UsersBulkInvite />} />
      <Route path={"edit/:userID"} element={<UserForm />} />
    </Routes>
  );
};
