import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { FileUpload } from "../shared/FileUpload";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: "60%",
  },
  grid: {
    padding: theme.spacing(2),
    justifyContent: "space-between",
  },
  listMain: {
    paddingLeft: theme.spacing(2),
    display: "list-item",
  },
  listSecondary: {
    paddingLeft: theme.spacing(4),
    display: "list-item",
  },
  listWithTooltip: {
    paddingLeft: theme.spacing(6),
    fontSize: ".875rem",
    color: "rgba(0, 0, 0, 0.6)",
  },
  listWithLink: {
    paddingLeft: theme.spacing(4),
  },
}));

const UsersBulkInviteFileUpload = () => {
  const classes = useStyles();
  const [selectedCSVFile, setSelectedCSVFile] = useState(null);
  const [parsedData, setParsedData] = useState();
  const navigate = useNavigate();
  var path = process.env.PUBLIC_URL;
  var csvTemplateFile = "/BulkInviteTemplate.txt";

  // Parse the CSV file into JSON format
  useEffect(() => {
    if (selectedCSVFile) {
      Papa.parse(selectedCSVFile, {
        header: true,
        complete: (results) => {
          setParsedData(results.data);
        },
      });
    }
  }, [selectedCSVFile]);

  useEffect(() => {
    if (parsedData) {
      navigate("/users/bulkuserinvite", { state: parsedData });
    }
  }, [parsedData]);
  return (
    <Paper className={classes.paper}>
      {" "}
      <List>
        <ListSubheader
          sx={{
            fontWeight: 700,
            lineHeight: "24px",
            fontSize: "24px",
            color: "black",
          }}
        >
          Bulk Invite Trainees
        </ListSubheader>
        <ListItem>
          <ListItemText
            className={classes.listMain}
            primary="Create a comma delimited .csv file that includes the fields below
            about each trainee. A template file is provided for assistance:"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listSecondary}
            secondary="- Trainee First Name"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listSecondary}
            secondary="- Trainee Last Name"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listSecondary}
            secondary="- Email Address"
          />
        </ListItem>
        <ListItem>
          <ListItemText className={classes.listSecondary} secondary="- NPI" />
        </ListItem>
        <ListItem>
          <ListItemText className={classes.listSecondary} secondary="- PGY" />
        </ListItem>
        <ListItem className={classes.listWithTooltip}>
          - Rater Status [Yes or No]{" "}
          <Tooltip
            placement="right"
            arrow
            title={
              <span>
                The rater status determines whether the trainee is listed as a
                possible rater in SIMPL Bedside. If left blank the rater status
                defaults to No.
              </span>
            }
          >
            <HelpOutlineIcon />
          </Tooltip>
        </ListItem>
        <ListItem>
          <ListItemText
            className={classes.listMain}
            primary="Click Upload and navigate to that file."
          />
        </ListItem>
        <ListItem className={classes.listWithLink}>
          <a href={path + csvTemplateFile} download="BulkInviteTemplate.csv">
            Download CSV Template
          </a>
        </ListItem>
      </List>
      <Grid container direction="column" alignItems="center">
        <FileUpload setFile={setSelectedCSVFile} />
      </Grid>
    </Paper>
  );
};

export default UsersBulkInviteFileUpload;
