import { API, graphqlOperation } from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { ProgramRoles } from "../../model/ApplicationConstants";
import { addToGroup, removeFromGroup } from "./UserAPI";
import { Exceptions } from "../../model/ApplicationConstants";

/**
 * Creates/Updates/Deletes memberships for a user based on the data entered on the user form.
 * @param {*} userFormData
 */
export async function updateMemberships(userFormData) {
  let currentUserMemberships = userFormData.memberships.items;
  let currentPermissionsLevel = getHighestPermissionLevel(
    currentUserMemberships
  );
  let updatedUserMemberships = userFormData.programs;
  let updatedPermissionsLevel = getHighestPermissionLevel(
    updatedUserMemberships
  );

  //Handle update and delete of memberships
  for (const currentUserMembership of currentUserMemberships) {
    let updatedMembership = updatedUserMemberships.find((membership) => {
      return membership.id === currentUserMembership.id;
    });
    if (updatedMembership !== undefined) {
      //existing membership, update if changed
      if (
        updatedMembership.role !== currentUserMembership.role ||
        updatedMembership.program.id !== currentUserMembership.programId
      ) {
        updateMembership(updatedMembership);
      }
    } else {
      deleteMembership(currentUserMembership);
    }
  }

  //Handle new memberships
  let newUserMemberships = updatedUserMemberships.filter(
    (membership) => membership.id === undefined
  );
  if (newUserMemberships.length > 0) {
    for (const newUserMembership of newUserMemberships) {
      createMembershipWithFormData(newUserMembership, userFormData.id);
    }
  }

  //Set new Cognito Permissions level (if necessary)
  if (
    userFormData.userName !== undefined &&
    currentPermissionsLevel !== updatedPermissionsLevel
  ) {
    setNewPermissionLevel(
      currentPermissionsLevel,
      updatedPermissionsLevel,
      userFormData.userName
    );
  }
}

/**
 * Take current Cognito permission level and updated Permission level, and update
 * the level in Cognito appropriately for the given user
 *
 * @param {*} current
 * @param {*} updated
 * @param {*} userName
 */
function setNewPermissionLevel(current, updated, userName) {
  switch (updated) {
    case ProgramRoles.Admin:
      addToGroup(userName, "Admins");
      break;
    case ProgramRoles.ProgramAdmin:
      if (current === ProgramRoles.Admin) {
        removeFromGroup(userName, "Admins");
      }
      addToGroup(userName, "ProgramAdmins");
      break;
    case ProgramRoles.User:
    default:
      if (current === ProgramRoles.Admin) {
        removeFromGroup(userName, "Admins");
      } else if (current === ProgramRoles.ProgramAdmin) {
        removeFromGroup(userName, "ProgramAdmins");
      }
  }
}

/**
 * Given the user's current program memberships, determine their highest level of Cognito permission
 *
 * @param {*} memberships
 * @returns
 */
function getHighestPermissionLevel(memberships) {
  if (
    memberships.filter((membership) => membership.role === ProgramRoles.Admin)
      .length > 0
  )
    return ProgramRoles.Admin;

  if (
    memberships.filter(
      (membership) => membership.role === ProgramRoles.ProgramAdmin
    ).length > 0
  )
    return ProgramRoles.ProgramAdmin;

  return ProgramRoles.User;
}

/**
 * Determines if a membership exists for the provided User and Program combination
 * @param {*} programId
 * @param {*} userId
 */
export async function membershipExists(programId, userId) {
  let operationParams = { programId, userId };
  let operation = graphqlOperation(
    customQueries.checkIfMembershipExists,
    operationParams
  );
  let response = await API.graphql(operation);
  return response.data.listMemberships.items.length > 0;
}

/**
 * Updates the user membership based on the program ID and program role provided
 * @param {*} userProgram
 */
async function updateMembership(userProgram) {
  let membershipValues = {
    id: userProgram.id,
    programId: userProgram.program.id,
    role: userProgram.role,
  };
  try {
    await API.graphql(
      graphqlOperation(mutations.updateMembership, {
        input: membershipValues,
      })
    );
  } catch (error) {
    console.log("Membership Update Error: ", error);
    throw Exceptions.UserMembershipUpdateFailure;
  }
}

/**
 * Delete the existing program membership from dynamodb
 *
 * @param {*} membership
 */
async function deleteMembership(membership) {
  console.log("Membership to delete: ", membership);
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteMembership, {
        input: { id: membership.id },
      })
    );
  } catch (error) {
    console.log("Membership Delete Error: ", error);
    throw Exceptions.UserMembershipDeleteFailure;
  }
}

/**
 * Create a new program membership for the user with given program and role
 *
 * @export
 * @param {*} userId
 * @param {*} programId
 * @param {*} role
 * @returns
 */
export async function createMembership(userId, programId, role) {
  try {
    const membership = await API.graphql(
      graphqlOperation(mutations.createMembership, {
        input: {
          programId,
          role,
          userId,
        },
      })
    );
    return membership;
  } catch (error) {
    console.log("Membership creation error: ", error);
    throw Exceptions.UserMembershipCreateFailure;
  }
}

/**
 * Take form data and parse it into data for creating a membership
 *
 * @export
 * @param {*} program
 * @param {*} userId
 */
export async function createMembershipWithFormData(program, userId) {
  console.log("Membership to create: ", program);
  createMembership(userId, program.program.id, program.role);
}
