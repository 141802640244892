import React from "react";

import Typography from "@mui/material/Typography";
import { Grid, Button, Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Avatar from "@mui/material/Avatar";

import { Link } from "react-router-dom";
import { generateProgString } from "../../util/UserUtil";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export const UserHeader = ({ userModel, loading }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Avatar className={classes.large} />
      </Grid>
      <Grid item xs={12} sm container alignItems="center" justifyContent="center">
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            { (!loading && userModel && (userModel.name || (userModel.firstName && userModel.lastName))) ?
              <Typography variant="h4">
                {userModel.name
                  ? userModel.name
                  : `${userModel.firstName} ${userModel.lastName}`}
              </Typography> 
              :
              <Skeleton variant="text" sx={{fontSize: '2.125rem'}} width="70%"/>
            }
            { (!loading && userModel && (userModel.programString || (userModel.programs && userModel.organization))) ?
              <Typography variant="body2">
                {userModel.programString
                  ? userModel.programString
                  : generateProgString(
                      userModel.programs,
                      userModel.organization
                    )}
              </Typography>
              : 
              <Skeleton variant="text" sx={{fontSize: '0.875rem'}} width="80%"/>
            }
            { (!loading && userModel && userModel.email) ?
              <Typography variant="body2">Email: {userModel.email}</Typography>
              : 
              <Skeleton variant="text" sx={{fontSize: '0.875rem'}} width="60%"/>
            }
            { (!loading && userModel) ?
              userModel.userName ?
              <Typography variant="body2">
                Username: {userModel.userName}
              </Typography> : null 
              :
              <Skeleton variant="text" sx={{fontSize: '0.875rem'}} width="50%"/>
            }
            { (!loading && userModel) ?
              userModel.pgy ?
              <Typography variant="body2">PGY: {userModel.pgy}</Typography> : null
              :
              <Skeleton variant="text" sx={{fontSize: '0.875rem'}} width="30%"/>
            }
          </Grid>
        </Grid>
        <Grid item xs>
          {userModel && userModel.id &&
            <Link to={`/users/edit/${userModel.id}`}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Edit User
              </Button>
            </Link>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
