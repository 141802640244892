import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import makeStyles from '@mui/styles/makeStyles';
import { OrganizationTable } from "./OrganizationTable";
import { fetchOrgs } from "../../api/OrganizationAPI";
import Typography from "@mui/material/Typography";

import { Button, Dialog, DialogTitle } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [newOrganizationFormOpen, setNewOrganizationFormOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function listOrgs() {
      let response = await fetchOrgs();
      setOrganizations(response);
    }
    listOrgs();
  }, []);

  const handleNewOrganizationFormOpen = () => {
    setNewOrganizationFormOpen(true);
  };
  const handleNewOrganizationFormClose = () => {
    setNewOrganizationFormOpen(false);
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Institutions</Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={handleNewOrganizationFormOpen}
          disabled={false}
        >
          Create Institution
        </Button>

        <Dialog
          open={newOrganizationFormOpen}
          onClose={handleNewOrganizationFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Institution</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter new institution information
            </DialogContentText>
            {/* Put new organization form here */}
          </DialogContent>
        </Dialog>
      </div>
      <OrganizationTable organizations={organizations} />
    </React.Fragment>
  );
};
