import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { Button, Dialog, DialogTitle } from "@mui/material";

import ProcedureTable from "./ProcedureTable";
import ProcedureForm from "./ProcedureForm";
import { listProcedures } from "../../api/ProcedureAPI";
import { useCurrentUser } from "../../../model/Users";
import { useCurrentOrganization } from "../../../model/Organization";
import { objectIsEmpty } from "../../util/Utility";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export const formatListForTable = (procedures, organization) => {
  let formattedList;
  if (procedures && procedures.length > 0) {
    formattedList = procedures.map((procedure) => {
      return {
        name: procedure.name,
        description: procedure.description,
        programString: createProgramString(
          procedure.programs.items,
          organization.id
        ),
      };
    });
  } else {
    formattedList = [{ name: "none", programString: "", isRater: "" }];
  }
  return formattedList;
};

export const createProgramString = (programs, orgId) => {
  let orgPrograms = programs.filter(
    (program) => program.program.orgID === orgId
  );
  if (orgPrograms.length > 0) {
    let names = orgPrograms.map((program) => {
      return program.program.name;
    });
    return `${names.join(", ")}`;
  }
};

export const Procedures = () => {
  const currentUser = useCurrentUser();
  const organization = useCurrentOrganization();
  const [procedureList, setProcedureList] = useState([]);
  const [newProcedureFormOpen, setNewProcedureFormOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function fetchProcedures() {
      let procedures;
      try {
        procedures = await listProcedures();
      } catch (exception) {
        console.log("Procedure fetch error: ", exception);
      }
      setProcedureList(formatListForTable(procedures, organization));
    }
    if (!objectIsEmpty(organization)) {
      fetchProcedures();
    }
  }, [newProcedureFormOpen, organization]);

  const handleNewProcedureFormOpen = () => {
    setNewProcedureFormOpen(true);
  };
  const handleNewProcedureFormClose = () => {
    setNewProcedureFormOpen(false);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Procedures</Typography>
        {currentUser.cognitoPermissions &&
          currentUser.cognitoPermissions.isAdmin && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewProcedureFormOpen}
            >
              Create Procedure
            </Button>
          )}

        <Dialog
          open={newProcedureFormOpen}
          onClose={handleNewProcedureFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New Procedure</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter new procedure information
            </DialogContentText>
            <ProcedureForm closeForm={handleNewProcedureFormClose} />
          </DialogContent>
        </Dialog>
      </div>
      <ProcedureTable procedures={procedureList} />
    </React.Fragment>
  );
};
