import React, { useState, useContext, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useCookies } from "react-cookie";

import AppContext from "../../../AppContext";
import { DispatchActions } from "../../../model/ApplicationConstants";
import { useCurrentUser } from "../../../model/Users";
import { useCurrentOrganization } from "../../../model/Organization";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const OrganizationTable = ({ organizations = [] }) => {
  const { dispatch } = useContext(AppContext);
  const currentUser = useCurrentUser();
  const organization = useCurrentOrganization();
  const classes = useStyles();
  const [checked, setChecked] = useState();
  const [cookies, setCookie] = useCookies(["sysAdminOrg"]);

  const handleToggle = (orgId) => () => {
    setOrganization(orgId);
  };

  async function setOrganization(orgId) {
    setChecked(orgId);
    setCookie("sysAdminOrg", orgId, { path: "/" });
    dispatch({
      type: DispatchActions.UpdateCurrentUser,
      payload: { ...currentUser, orgCookie: orgId },
    });
    dispatch({ type: DispatchActions.UpdateOrganization });
  }

  useEffect(() => {
    if (organization === undefined) {
      return;
    } else if (checked === undefined) {
      setChecked(organization.id);
    }
  }, [organization, checked]);

  return (
    <List className={classes.root}>
      {organizations.map((organization) => {
        return (
          <ListItem
            key={organization.id}
            role={undefined}
            dense
            button
            onClick={handleToggle(organization.id)}
          >
            <FormControlLabel
              control={<Radio />}
              checked={checked === organization.id}
              tabIndex={-1}
            />
            <ListItemText primary={organization.title}></ListItemText>
            <ListItemText secondary={organization.description}></ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};
