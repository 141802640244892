// @flow
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DomainIcon from "@mui/icons-material/Domain";
import type { Program } from "../../../model/Programs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  links: {
    textDecoration: "none",
    color: "inherit",
  },
}));

type ProgramTableProps = {
  programs: Array<Program>,
};

export const ProgramTable = ({ programs }: ProgramTableProps) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {programs.map((program) => {
        return (
          <Link className={classes.links} to={`../program/${program.id}`}>
            <ListItem key={program.id}>
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText primary={program.name}></ListItemText>
              <ListItemText secondary={program.description}></ListItemText>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};
