import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import { fetchCertRecordsForTable } from "../api/CertRecordAPI";
import { fetchEvalResponsesForTable } from "../api/EvalResponseAPI";
import { fetchOrganization } from "../api/OrganizationAPI";
import { fetchOperationAutoPaginate } from "../api/APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

export function generateProgString(programs, organization) {
  if (programs === undefined) {
    return;
  }
  if (organization === undefined || organization.title === undefined) {
    return;
  }
  if (programs.length > 0) {
    let names = programs.map((program) => {
      return program.program.name;
    });
    return `${names.join(", ")} at ${organization.title}`;
  } else {
    return organization.title;
  }
}

export function autoCapitalizeString(inputText) {
  var nextCapital = true;
  var newString = "";
  for (var i = 0; i < inputText.length; i++) {
    var cur = inputText.charAt(i);
    if (nextCapital) {
      newString += cur.toUpperCase();
    } else {
      newString += cur.toLowerCase();
    }
    if (cur === " ") {
      nextCapital = true;
    } else {
      nextCapital = false;
    }
  }
  return newString;
}

export async function fetchUsers(organization) {
  var listItems = [];
  try {
    let response = await API.graphql(
      graphqlOperation(customQueries.getUserList, {
        filter: { orgID: { eq: organization.id } },
        limit: 1000,
      })
    );

    response.data.listUsers.items.map((user) => {
      let v = {
        id: user.id,
        username: user.userName,
        name: `${user.lastName}, ${user.firstName}`,
        email: user.email,
        npi: user.npi,
        isRater: user.isRater,
        pgy: user.pgy,
        programs: generateProgString(user.memberships.items, organization),
        memberships: user.memberships.items,
        lastLogin: user.lastLogin,
      };
      listItems.push(v);
      return null;
    });
    let nextToken = response.data.listUsers.nextToken;

    while (nextToken !== null) {
      let tokenResponse = await API.graphql(
        graphqlOperation(customQueries.getUserList, {
          filter: { orgID: { eq: organization.id } },
          limit: 1000,
          nextToken: nextToken,
        })
      );
      tokenResponse.data.listUsers.items.map((user) => {
        let v = {
          id: user.id,
          username: user.userName,
          name: `${user.lastName}, ${user.firstName}`,
          email: user.email,
          npi: user.npi,
          isRater: user.isRater,
          pgy: user.pgy,
          programs: generateProgString(user.memberships.items, organization),
          memberships: user.memberships.items,
          lastLogin: user.lastLogin,
        };
        listItems.push(v);
        return null;
      });
      nextToken = tokenResponse.data.listUsers.nextToken;
    }
  } catch (error) {
    console.error("Fetch Users Error: ", error);
  }

  return listItems;
}

export async function fetchUserAndPrograms(userId) {
  // Get user data by Id
  const userQueryResponse = await API.graphql(
    graphqlOperation(queries.getUser, { id: userId })
  );
  let user = userQueryResponse.data.getUser;

  // Get all program memberships
  const operation = graphqlOperation(queries.listMemberships, {
    filter: { userId: { eq: user.id } },
    limit: 100,
  });
  let programs;
  try {
    programs = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.UserMembershipFetchFailure;
  }

  let organization;
  try {
    organization = await fetchOrganization(user.orgID);
  } catch (error) {
    console.error("Error fetching Organization: ", error);
  }

  let certRecords;
  try {
    certRecords = await fetchCertRecordsForTable(userId);
  } catch (error) {
    console.error("Error fetching Certification Records: ", error);
    if (certRecords) {
      certRecords = removeNullValues(error.data.certRecordsBySubject.items);
    }
  }
  let evalResponses;
  try {
    evalResponses = await fetchEvalResponsesForTable(userId);
  } catch (error) {
    console.error("Error fetching Evaluation Responses: ", error);
    if (evalResponses) {
      evalResponses = removeNullValues(error.data.evalsBySubject.items);
    }
  }
  let pgy = user.pgy === null ? "" : user.pgy;
  let userData = {
    ...user,
    organization: organization,
    programs: programs,
    certificationRecords: certRecords,
    evaluationResponses: evalResponses,
    pgy,
  };
  return userData;
}

function removeNullValues(data) {
  var i;
  for (i = data.length; i--; ) {
    if (!data[i]) {
      data.splice(i, 1);
    }
  }
  return data;
}