export const ProgramRoles = {
  User: "User",
  ProgramAdmin: "ProgramAdmin",
  Admin: "Admin",
};

export const ProgramRolesArray = [ProgramRoles.User, ProgramRoles.ProgramAdmin, ProgramRoles.Admin];

export const ProgramRolesSelectFields = [
  { label: "User", value: ProgramRoles.User },
  { label: "Program Administrator", value: ProgramRoles.ProgramAdmin },
  { label: "Institutional Administrator", value: ProgramRoles.Admin },
];

/**
 * Program roles are about to change so that there
 * isn't a 1:1 mapping between the role a user has in
 * a program and the permissions they are granted in
 * cognito.
 */
export const UserPermissions = {
  Users: "Users",
  ProgramAdmins: "ProgramAdmins",
  Admins: "Admins",
  Sysadmins: "Sysadmins",
};

/**
 * Update this when new roles are added to the system
 */
export const ProgramRoleToUserPermissionMapping = {
  [ProgramRoles.User]: UserPermissions.Users,
  [ProgramRoles.ProgramAdmin]: UserPermissions.ProgramAdmins,
  [ProgramRoles.Admin]: UserPermissions.Admins,
};

export const PGYValues = [
  undefined,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12
]

export const PGYs = [
  { label: "N/A", value: PGYValues[0] },
  { label: "PGY 1", value: PGYValues[1] },
  { label: "PGY 2", value: PGYValues[2] },
  { label: "PGY 3", value: PGYValues[3] },
  { label: "PGY 4", value: PGYValues[4] },
  { label: "PGY 5", value: PGYValues[5] },
  { label: "PGY 6", value: PGYValues[6] },
  { label: "PGY 7", value: PGYValues[7] },
  { label: "PGY 8", value: PGYValues[8] },
  { label: "PGY 9", value: PGYValues[9] },
  { label: "PGY 10", value: PGYValues[10] },
  { label: "PGY 11", value: PGYValues[11] },
  { label: "PGY 12+", value: PGYValues[12] },
];



export const DispatchActions = {
  UpdateCurrentUser: "UPDATE_CURRENT_USER",
  UpdateOrganization: "UPDATE_ORGANIZATION",
  FetchCurrentUser: "FETCH_CURRENT_USER",
};

export const performanceValueMap = (ratingLevel) => {
  switch (ratingLevel) {
    case 1:
      return "Unprepared/Critical Deficiency";
    case 2:
      return "Inexperienced";
    case 3:
      return "Intermediate";
    case 4:
      return "Practice Ready";
    case 5:
      return "Exceptional";
    default:
      return "";
  }
};

export const Exceptions = {
  CertRecordExists: "CERTIFICATION_RECORD_EXISTS",
  CertRecordFetchFailure: "CERTIFICATION_RECORD_FETCH_FAILURE",
  CertRecordLookupFailure: "CERTIFICATION_RECORD_LOOKUP_FAILURE",
  CertRecordUpdateFailure: "CERTIFICATION_RECORD_UPDATE_FAILURE",
  EvaluationFetchFailure: "EVALUATION_FETCH_FAILURE",
  NoInvitationExistsFailure: "INVITATION_DOES_NOT_EXIST",
  OrgProgramFetchFailure: "ORGANIZATION_PROGRAM_FETCH_FAILURE",
  OrgDataFetchFailure: "ORGANIZATION_DATA_FETCH_FAILURE",
  ProcedureFetchFailure: "PROCEDURE_FETCH_FAILURE",
  ProgramFetchFailure: "PROGRAM_FETCH_FAILURE",
  ProgramProcedureExists: "PROGRAM_PROCEDURE_EXISTS",
  ProgramProcedureDeleteFailure: "PROGRAM_PROCEDURE_DELETE_FAILURE",
  ProgramProcedureLookupFailure: "PROGRAM_PROCEDURE_LOOKUP_FAILURE",
  ProgramProcedureFetchFailure: "PROGRAM_PROCEDURE_FETCH_FAILURE",
  UserByEmailFetchFailure: "USER_BY_EMAIL_FETCH_FAILURE",
  UserByEmailCountFailure: "USER_BY_EMAIL_COUNT_FAILURE",
  UserConfirmationCodeFailure: "USER_CONFIRMATION_CODE_FAILURE",
  UserConfirmationFailure: "USER_CONFIRMATION_FAILURE",
  UserCreationFailure: "USER_CREATION_FAILURE",
  UsernameExistsFailure: "USERNAME_EXISTS_FAILURE",
  UserInvitationCreationFailure: "USER_INVITATION_CREATION_FAILURE",
  UserMembershipExists: "USER_MEMBERSHIP_EXISTS",
  UserMembershipLookupFailure: "USER_MEMBERSHIP_LOOKUP_FAILURE",
  UserMembershipFetchFailure: "USER_MEMBERSHIP_FETCH_FAILURE",
  UserMembershipUpdateFailure: "USER_MEMBERSHIP_UPDATE_FAILURE",
  UserMembershipDeleteFailure: "USER_MEMBERSHIP_DELETE_FAILURE",
  UserMembershipCreateFailure: "USER_MEMBERSHIP_CREATE_FAILURE",
  UserUpdateCognitoGroupFailure: "USER_UPDATE_COGNITO_GROUP_FAILURE",
  UserAddToCognitoGroupFailure: "USER_ADD_TO_COGNITO_GROUP_FAILURE",
  UserRemoveFromCognitoGroupFailure: "USER_REMOVE_FROM_COGNITO_GROUP_FAILURE",
};

export const NurseReportFields = {
  userHomeLinkText: "Program Administrator Login",
  reportTitle1: "Bedside Procedures Certification Status",
  reportTitle2: "for",
  noCertifiedProceduresText:
    "N/A. Trainee has no certified bedside procedures.",
  reportInstructions:
    "Select a trainee's name to display the list of bedside procedures their\
     program director has indicated that the trainee is certified to perform\
      under indirect supervision and those procedures that the trainee is not\
       yet certified to perform under indirect supervision.",
  simplLinkText:
    "SIMPL-Bedside is provided by the Society for Improving Medical Professional Learning, a 501c3 nonprofit dedicated to improving the quality of medical education.",
  devsandboxRestUrl:
    "https://tamlpg3zji.execute-api.us-east-1.amazonaws.com/devsandbox/bedsidereport/nursereport",
  developRestUrl:
    "https://2a614154m9.execute-api.us-east-1.amazonaws.com/dev/bedsidereport/nursereport",
  prodRestUrl:
    "https://yck6pch5gl.execute-api.us-east-1.amazonaws.com/prod/bedsidereport/nursereport",
};

export const NameTypes = {
  First: "first",
  Last: "last",
}

export const InviteUserErrors = {
  EmptyEmail: "Please enter a value for the email address.",
  EmailInUse: <span>This email is in use. Please reach out to <a href="mailto:help@simple.org" target="_blank" rel="noreferrer">help@simpl.org</a> for questions.</span>,
  EmailTooLong: "Email addresses cannot be more than 256 characters",
  ImproperlyFormattedEmail: "Please enter a properly formatted email address",
  NoNameType: "Please specify a name type (first or last).",
  EmptyFirstName: "Please enter a value for the first name.",
  EmptyLastName: "Please enter a value for the last name.",
  FirstNameTooLong: "First name must be 50 characters or less.",
  LastNameTooLong: "Last name must be 50 characters or less.",
  NoRoleSelected: "Please specify a role.",
  InvalidRole: "This is not a valid role. Please select 'User' 'Institution Administrator' or 'Program Administrator'",
  NoProgramSelected: "Users with the role 'User' or 'Program Admininistrator' must select a program.",
  NpiRequired: "Users with the role 'User' must specify an NPI.",
  InvalidNpi: "NPIs must be a 10 digit number that begins with the number 1.",
  NpiInUse: <span>This NPI is in use. Please reach out to <a href="mailto:help@simple.org" target="_blank" rel="noreferrer">help@simpl.org</a> for questions.</span>,
  PgyOutOfBounds: "Selected PGY is not in the approved list of values",
  MissingData: "Unable to submit form (invite object missing)",
  SubmissionError: <span>There was an error submitting this invitation. Please try again or reach out to <a href="mailto:help@simple.org" target="_blank" rel="noreferrer">help@simpl.org</a> if the problem persists.</span>,
  DataFetchError: <span>There was an error fetching this user's data. Please check with your administrator or reach out to <a href="mailto:help@simple.org" target="_blank" rel="noreferrer">help@simpl.org</a> if the problem persists.</span>,
}

export const InviteUserFormFields = {
  Email: "email",
  FirstName: "firstName",
  LastName: "lastName",
  Role: "role",
  Program: "program",
  NPI: "npi",
  PGY: "pgy",
}

export const UserRoleChoices = {
  User: { label: "User", value: ProgramRoles.User },
  ProgramAdmin: { label: "Program Administrator", value: ProgramRoles.ProgramAdmin },
  Admin: { label: "Institutional Administrator", value: ProgramRoles.Admin },
}