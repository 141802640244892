// @flow
import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useProcedures } from "../../../model/Procedure";
import MaterialTable from "@material-table/core";
import { newCertificationRecord } from "../../api/CertRecordAPI";
import SnackbarAlert from "../shared/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { fetchUserAndPrograms } from "../../util/UserUtil";

type UserProcedureCertificationTableProps = {
  userId: string,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const UserProcedureCertificationTable = ({
  userId,
}: UserProcedureCertificationTableProps) => {
  const classes = useStyles();
  const procedures = useProcedures();
  // const user = useUser(userId);
  const [user, setUser] = useState();
  const [certifiableProcedures, setCertifiableProcedures] = useState([]);
  const [certifiedProcedures, setCertifiedProcedures] = useState([]);
  const [certifiedProceduresLoaded, setCertifiedProceduresLoaded] =
    useState(false);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    severity: "success",
    message: "",
  });
  const [loadingOpen, setLoadingOpen] = useState(true);

  const updateUser = async (userId) => {
    try {
      setLoadingOpen(true);
      const updatedUser = await fetchUserAndPrograms(userId);
      setUser(updatedUser);
      setLoadingOpen(false);
    } catch (error) {
      console.error("unable to fetch user with Id:", userId, error);
    }
  }

  // Initial load of user
  useEffect(() => {
    updateUser(userId);
  }, [userId])

  // Set certified procedures from user certification records.
  useEffect(() => {
    if (!user || !user.certificationRecords) {
      return;
    }
    const procs = user.certificationRecords.map((certRecord) => {
      return certRecord.procedure;
    });
    setCertifiedProceduresLoaded(true);
    setCertifiedProcedures(procs);
  }, [user]);

  // Set certifiable procedures
  useEffect(() => {
    if (!procedures || !certifiedProceduresLoaded) {
      return;
    }

    const certifiedProcedureIds = certifiedProcedures.map((procedure) => {
      return procedure && procedure.id;
    });

    const certifiableProcedures = procedures.filter((procedure) => {
      return !certifiedProcedureIds.includes(procedure.id);
    });

    setCertifiableProcedures(certifiableProcedures);
    setLoadingOpen(false);
  }, [certifiedProcedures, certifiedProceduresLoaded, procedures]);

  const onAddProcedure = async (event, procedure) => {
    setLoadingOpen(true);
    try {
      await newCertificationRecord(user.id, procedure.id);

      setAlertState({
        isOpen: true,
        severity: "success",
        message: "Certification Record Added",
      });
      updateUser(userId)
    } catch (error) {
      console.error("Error creating certification record");

      setAlertState({
        isOpen: true,
        severity: "error",
        message: "Add Certification Record Failed",
      });
    }
  };

  const onAlertClose = () => {
    setAlertState({ ...alertState, isOpen: false });
  };

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />{" "}
      <MaterialTable
        title="Select a Procedure"
        columns={[
          {
            title: "Procedure",
            field: "name",
            editable: "never",
          },
        ]}
        localization={{
          header: {
            actions: "Add",
          },
        }}
        data={certifiableProcedures}
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          {
            icon: "add",
            tooltip: "add certification record",
            onClick: onAddProcedure,
          },
        ]}
      ></MaterialTable>
      <SnackbarAlert
        open={alertState.isOpen}
        onClose={onAlertClose}
        severity={alertState.severity}
        message={alertState.message}
      />
      <div>
        <Backdrop className={classes.backdrop} open={loadingOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </React.Fragment>
  );
};
