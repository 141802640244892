import { graphqlOperation } from "aws-amplify";
import * as customQueries from "../../graphql/customQueries";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

export async function listProcedures() {
  const operation = graphqlOperation(customQueries.listProcedures, {
    limit: 100,
  });
  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.ProcedureFetchFailure;
  }

  const sorted = response.sort((p1, p2) => {
    return p1.name > p2.name ? 1 : -1;
  });
  //Temporary archiving feature (until full archiving implemented)
  return sorted.filter((procedure) => !procedure.name.includes("Invalid - "));
}
