import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MaterialTable from "@material-table/core";
import SnackbarAlert from "../shared/SnackbarAlert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { useProcedures } from "../../../model/Procedure";
import {
  newProgramProcedure,
  removeProgramProcedure,
} from "../../api/ProgramProcedureAPI";
import ConfirmDialog from "../shared/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const setProgramProcedureFlags = (procedures, mappedProcedures) => {
  return procedures.map((p) => {
    return {
      id: p.id,
      name: p.name,
      isProgramProcedure:
        mappedProcedures.filter((mp) => mp.procedureId === p.id).length > 0,
    };
  });
};

export const ProgramProceduresUpdate = ({
  programProcedures = [],
  program,
}) => {
  const classes = useStyles();
  const procedures = useProcedures();
  const [procedureList, setProcedureList] = useState();
  const [mappedProcedures, setMappedProcedures] = useState(programProcedures);
  const [selectedProcedure, setSelectedProcedure] = useState();
  const [loadingOpen, setLoadingOpen] = useState(true);
  const [actionType, setActionType] = useState();
  const [confirmState, setConfirmState] = useState({
    isOpen: false,
    confirmed: false,
    message: "test",
    confirmButton: "Ok",
  });
  const [alertState, setAlertState] = useState({
    isOpen: false,
    severity: "success",
    message: "",
  });

  // Set procedure list
  useEffect(() => {
    setLoadingOpen(true);
    if (!procedures) {
      return;
    }
    const fullProcedureList = setProgramProcedureFlags(
      procedures,
      mappedProcedures
    );
    setProcedureList(fullProcedureList);
    setLoadingOpen(false);
  }, [procedures, mappedProcedures]);

  useEffect(() => {
    //If confirmed is "false", do nothing
    if (!confirmState.confirmed) return;
    //Call the correct function based on the current action
    if (actionType === "Add") {
      addProcedure();
    } else if (actionType === "Remove") {
      removeProcedure();
    } else return;
  }, [confirmState.confirmed, actionType]);

  const onAddProcedure = (event, procedure) => {
    //Populate message for confirm dialog
    var message = `Are you sure you want to add ${procedure.name} to the program ${program.name}?`;
    setConfirmState({
      ...confirmState,
      isOpen: true,
      message: message,
      confirmButton: "Add",
    });
    //Set procedure to add
    setSelectedProcedure(procedure);
    //Open confirm dialog and set the current action to ADD
    setActionType("Add");
  };

  const addProcedure = async () => {
    setConfirmState({ ...confirmState, confirmed: false });
    console.log("add procedure: ", selectedProcedure);
    setLoadingOpen(true);
    try {
      let newProcedure = await newProgramProcedure(
        selectedProcedure.id,
        program.id
      );
      setAlertState({
        isOpen: true,
        severity: "success",
        message: `${selectedProcedure.name} added to ${program.name}`,
      });
      //Update UI state to disable add and enable delete for the new ProgramProcedure
      let newMappedProcedures = [...mappedProcedures];
      newMappedProcedures.push(newProcedure);
      setMappedProcedures(newMappedProcedures);
    } catch (error) {
      console.log("Error creating program procedure");
      setAlertState({
        isOpen: true,
        severity: "error",
        message: "Add Program Procedure Failed",
      });
    }
    setLoadingOpen(false);
  };

  const onRemoveProcedure = (event, procedure) => {
    //Populate message for confirm dialog
    var message = `Are you sure you want to remove ${procedure.name} from the program ${program.name}?`;
    setConfirmState({
      ...confirmState,
      isOpen: true,
      message: message,
      confirmButton: "Remove",
    });
    //Set procedure to remove
    setSelectedProcedure(procedure);
    //Open confirm dialog and set the current action to REMOVE
    setActionType("Remove");
  };

  const removeProcedure = async () => {
    setConfirmState({ ...confirmState, confirmed: false });
    console.log("remove procedure: ", selectedProcedure);
    let newMappedProcedures = [...mappedProcedures];
    let index = newMappedProcedures.findIndex(
      (mp) => mp.procedureId === selectedProcedure.id
    );
    setLoadingOpen(true);
    try {
      await removeProgramProcedure(newMappedProcedures[index].id);
      if (index !== -1) {
        newMappedProcedures.splice(index, 1);
        setMappedProcedures(newMappedProcedures);
      } else {
        console.log("Unable to remove item from display list");
      }
      setAlertState({
        isOpen: true,
        severity: "success",
        message: `${selectedProcedure.name} removed from ${program.name}`,
      });
    } catch (error) {
      console.log("Error deleting program procedure: ", error);
      setAlertState({
        isOpen: true,
        severity: "error",
        message: "Remove Program Procedure Failed",
      });
      setLoadingOpen(false);
    }
  };

  const onAlertClose = () => {
    setAlertState({ ...alertState, isOpen: false });
  };

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />{" "}
      <MaterialTable
        title=""
        columns={[
          {
            title: "Procedure",
            field: "name",
            editable: "never",
          },
          {
            title: "ID",
            field: "id",
            editable: "never",
            hidden: true,
          },
          {
            title: "IsProgramProcedure",
            field: "isProgramProcedure",
            editable: "never",
            hidden: true,
          },
        ]}
        localization={{
          header: {
            actions: "Add/Remove",
          },
        }}
        data={procedureList}
        options={{
          search: true,
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={[
          (rowData) => ({
            icon: "add",
            tooltip: "Add Procedure to Program",
            onClick: onAddProcedure,
            disabled: rowData.isProgramProcedure,
          }),
          (rowData) => ({
            icon: "remove",
            tooltip: "Remove Procedure from Program",
            onClick: onRemoveProcedure,
            disabled: !rowData.isProgramProcedure,
          }),
        ]}
      ></MaterialTable>
      <ConfirmDialog
        title="Warning!"
        confirmState={confirmState}
        setConfirmState={setConfirmState}
      />
      <SnackbarAlert
        open={alertState.isOpen}
        onClose={onAlertClose}
        severity={alertState.severity}
        message={alertState.message}
        vertical="bottom"
      />
      <span>
        <Backdrop className={classes.backdrop} open={loadingOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </span>
    </React.Fragment>
  );
};
