import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  form: {
    paddingLeft: 25,
    paddingTop: 25,
  },
}));

const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.form}>
      <h1>Welcome to SIMPL Bedside!</h1>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoadingPage;
