import { useContext, useEffect, useState } from "react";
import { fetchUserAndPrograms } from "../components/util/UserUtil";
import AppContext from "../AppContext";

export function useCurrentUser() {
  const appState = useContext(AppContext);
  const [currUser, setCurrUser] = useState({});
  const {
    state: { currentUser },
  } = appState;

  useEffect(() => {
    setCurrUser(currentUser);
  }, [currentUser]);

  return currUser;
}

export function useUser(id) {
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!id) {
      return;
    }

    const getUser = async () => {
      const u = await fetchUserAndPrograms(id);
      setUser(u);
    };

    getUser(id);
  }, [id]);

  return user;
}
