import React from "react";
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
import CSSBaseLine from "@mui/material/CssBaseline";
import "typeface-roboto";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material/styles";

import "@aws-amplify/ui/dist/style.css";

import ProtectedApp from "./ProtectedApp";
import UserInvitationResponseForm from "./main/users/UserInvitationResponseForm";
import UserConfirmationForm from "./main/users/UserConfirmationForm";
import UserCertifiedProcedureReport from "./main/reports/UserCertifiedProcedureReport";
import UserInfo from "./main/users/UserInfo";
import { RetrieveUsername } from "./login/RetrieveUsername";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
Amplify.configure(awsconfig);

const theme = createTheme(
  adaptV4Theme({
    links: {
      textDecoration: "none",
      color: "inherit",
    },
  })
);

function App() {
  return (
    <Router>
      <CSSBaseLine />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/*" element={<ProtectedApp />} />
            <Route
              path="invitation/:email"
              element={<UserInvitationResponseForm />}
            />
            <Route
              path="confirmation/:username/:permission"
              element={<UserConfirmationForm />}
            />
            <Route path="userinfo" element={<UserInfo />} />
            <Route path="/retrieveusername" element={<RetrieveUsername />} />
            <Route
              path="certifiedprocedurereport/:orgId"
              element={<UserCertifiedProcedureReport />}
            />
          </Routes>
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
