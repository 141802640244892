import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import {
  Backdrop,
  CircularProgress,
  Button,
  MenuItem,
  Select,
} from "@mui/material";

import { emailExists, npiExists } from "../../api/UserAPI";
import { createInvitation } from "../../api/InvitationAPI";
import { listPrograms } from "../../api/ProgramAPI";
import { objectIsEmpty } from "../../util/Utility";
import { UserInvitationTable } from "./UserInvitationTable";
import { useCurrentUser } from "../../../model/Users";
import { useCurrentOrganization } from "../../../model/Organization";
import SnackbarAlert from "../shared/SnackbarAlert";
import ConfirmDialog from "../shared/ConfirmDialog";

const EMAIL_ALERT = "Contact help@simpl.org with questions.";
const UNKNOWN_ERROR = "UNKNOWN ERROR";
const SUCCESS = "SUCCESS";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
    justifyContent: "space-between",
  },
  columns: {
    "& > *": {
      padding: theme.spacing(2),
    },
  },
  programSelect: {
    minWidth: "150px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const UsersBulkInvite = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const [fileData, setFileData] = useState();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const organization = useCurrentOrganization();
  const currentUser = useCurrentUser();
  const [currentProgram, setCurrentProgram] = useState();
  const [programs, setPrograms] = useState();
  const [invitationList, setInvitationList] = useState(() => {
    const initialState = mapData(state);
    return initialState;
  });
  const [alertState, setAlertState] = useState({
    isOpen: false,
    message: "Test Test",
    severity: "success",
  });
  const [confirmState, setConfirmState] = useState({
    isOpen: false,
    confirmed: false,
    message: "test",
    confirmButton: "Yes",
  });

  function mapData(data) {
    console.log("data: ", data);
    let mappedData = data.map((row) => {
      console.log("Row: ", row);
      // Ensure that the CSV file data is in the proper format
      if (
        row.hasOwnProperty("firstName") &&
        row.hasOwnProperty("lastName") &&
        row.hasOwnProperty("email") &&
        row.hasOwnProperty("npi") &&
        row.hasOwnProperty("pgy") &&
        (row.hasOwnProperty("raterStatus") || row.hasOwnProperty("isRater"))
      ) {
        // Default rater status to false, only return true if entry is "yes"
        let raterStatus = "false";
        if (row.raterStatus) {
          raterStatus = row.raterStatus === "yes" ? "true" : "false";
        } else if (row.isRater) {
          raterStatus = row.isRater === "true" ? "true" : "false";
        }
        console.log("Rater status: ", raterStatus);
        return {
          // Default to User role
          email: row.email,
          lastName: row.lastName,
          firstName: row.firstName,
          pgy: row.pgy,
          npi: row.npi,
          role: "User",
          program: currentProgram ? currentProgram.id : null,
          orgID: organization.id ? organization.id : null,
          programAdminId: currentUser.id ? currentUser.id : null,
          isRater: raterStatus,
        };
      } else {
        setErrorAlert("CSV File is not in proper format.");
      }
    });

    return mappedData;
  }

  useEffect(() => {
    console.log("Invitation list: ", invitationList);
  }, [invitationList]);

  // Map CSV data to Invitation List
  useEffect(() => {
    console.log("Running?  ", organization, currentUser);
    if (organization && currentUser) {
      console.log("Mapping?  ", invitationList);
      // map data to the invitation list.  use invitation list to populate table.
      let newData = mapData(invitationList);
      setInvitationList([...newData]);
    }
  }, [organization, currentUser, currentProgram]);

  // Load programs to populate the Program select
  useEffect(() => {
    async function getPrograms() {
      let programList = await listPrograms(organization.id);
      // Sysadmins and Institutional Admins should see all programs
      //   Program Admins should only programs they are admin of
      if (currentUser.cognitoPermissions.isAdmin) {
        setPrograms([...programList]);
      } else if (currentUser.cognitoPermissions.isProgramAdmin) {
        let paPrograms = programList.filter(
          (program) =>
            currentUser.memberships.items.filter(
              (membership) =>
                membership.role === "ProgramAdmin" &&
                membership.programId === program.id
            ).length > 0
        );
        setPrograms([...paPrograms]);
        // If only admin of 1 program, set as default
        if (paPrograms.length === 1) {
          setCurrentProgram(paPrograms[0]);
        }
      }
    }
    if (objectIsEmpty(currentUser) || objectIsEmpty(organization)) {
      return;
    }
    getPrograms();
  }, [currentUser, organization]);

  // When Program select is changed, handle the event
  const handleProgramChange = (event) => {
    let programId = event.target.value;
    const selectedProgram = programs.find(
      (program) => program.id === programId
    );
    setCurrentProgram(selectedProgram);
  };

  function handleClick() {
    if (currentProgram) {
      //Populate message for confirm dialog
      var message = `Send ${invitationList.length} invitations for ${currentProgram.name}?`;
      setConfirmState({
        ...confirmState,
        isOpen: true,
        message: message,
        confirmButton: "Yes",
      });
    } else {
      setErrorAlert("Please select a program from the dropdown.");
    }
  }

  useEffect(() => {
    //If confirmed is "false", do nothing
    if (!confirmState.confirmed) return;
    else {
      processInvitations();
      setConfirmState({
        ...confirmState,
        confirmed: false,
      });
    }
  }, [confirmState.confirmed]);

  // When Send Invitations button is pressed, begin processing the invitation table
  async function processInvitations() {
    if (invitationList.length > 1) {
      for (var i = 0; i < invitationList.length; i++) {
        try {
          setLoadingOpen(true);
          // npi and email in use test
          let isEmailUsed = await emailExists(invitationList[i].email);
          let isNPIUsed = await npiExists(invitationList[i].npi);

          if (!isEmailUsed && !isNPIUsed) {
            await createInvitation(invitationList[i]);
            setLoadingOpen(false);
            console.log(
              "Invitation process started for: ",
              invitationList[i].email
            );
            invitationList[i].invitationStatus = SUCCESS;
            setSuccessAlert("Invitation Sent.");
          } else {
            let statusMessage = "";
            if (isEmailUsed) {
              console.log("email error");
              // populate error status field
              statusMessage += "Email in use. ";
            }
            if (isNPIUsed) {
              console.log("NPI error");
              // populate error status field
              statusMessage += "NPI in use. ";
            }
            //throw error w/ message populated
            invitationList[i].invitationStatus = statusMessage;
            setLoadingOpen(false);
            setErrorAlert(statusMessage + EMAIL_ALERT);
          }
        } catch (error) {
          console.log(error);
          setLoadingOpen(false);
          setErrorAlert(
            "An error occured while inviting this user. Please try again later"
          );
          invitationList[i].invitationStatus = UNKNOWN_ERROR;
        }
      }
    }
  }

  function setErrorAlert(message) {
    setAlertState({ isOpen: true, severity: "error", message });
  }

  function setSuccessAlert(message) {
    setAlertState({ isOpen: true, severity: "success", message });
  }

  const onErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ ...alertState, isOpen: false });
  };

  return (
    <div>
      <p />
      <Select
        className={classes.programSelect}
        variant="standard"
        inputProps={{
          name: "programSelect",
          id: "current-program-select",
        }}
        value={currentProgram && currentProgram.id ? currentProgram.id : ""}
        onChange={handleProgramChange}
      >
        {programs &&
          programs.map((program) => {
            return <MenuItem value={program.id}>{program.name}</MenuItem>;
          })}
      </Select>
      <p />
      <UserInvitationTable
        invitations={invitationList}
        setInvitations={setInvitationList}
      ></UserInvitationTable>
      <p />
      <Button
        component="label"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Send Invitations
      </Button>
      <SnackbarAlert
        open={alertState.isOpen}
        onClose={onErrorClose}
        severity={alertState.severity}
        message={alertState.message}
      />
      <div>
        <ConfirmDialog
          confirmState={confirmState}
          setConfirmState={setConfirmState}
        />
        <Backdrop className={classes.backdrop} open={loadingOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default UsersBulkInvite;
