import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { Formik, Form } from "formik";
import FormikField from "../shared/FormikField";

import { Divider, Grid, Paper, Button } from "@mui/material";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { Exceptions } from "../../../model/ApplicationConstants";
import SnackbarAlert from "../shared/SnackbarAlert";
import { createAccount } from "../../api/UserAPI";
import { fetchInvitations } from "../../api/InvitationAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  columns: {
    "& > *": {
      padding: theme.spacing(2),
    },
  },
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
  form: {
    paddingLeft: 25,
    paddingTop: 25,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const NewUserSchema = Yup.object({
  username: Yup.string()
    .min(4, "must be at least 4 characters")
    .max(128, "username cannot be more than 128 characters")
    .matches(/^[^/]+$/, "No / allowed in username field.")
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "password must be at least 8 characters")
    .max(99, "password must be less than 100 characters"),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "passwords must match")
    .required("Required"),
});

const UserInvitationResponseForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [invitations, setInvitations] = useState([]);
  const [wasInvited, setWasInvited] = useState([]);
  const email = params.email;

  useEffect(() => {
    async function fetch() {
      const invitations = await fetchInvitations(email);
      console.log("invitations", invitations);
      setInvitations(invitations);
      setWasInvited(
        invitations.filter((invite) => invite.email === email).length > 0
      );
    }
    fetch();
  }, [email]);

  const newUserModel = {
    id: "",
    username: "",
    email: email,
    password: "",
    orgID: "",
    repeatPassword: "",
  };

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    message: "",
    severity: "",
  });

  async function onSubmit(values) {
    setLoadingOpen(true);
    let success = false;
    let permissions;
    try {
      permissions = invitations[0].permission;
      await createAccount({ ...values, permissions });
      setSuccessAlert("User Account Successfully Created.");
      success = true;
      setLoadingOpen(false);
    } catch (exception) {
      //TODO: handle other specific exceptions and display message to user
      if (exception === Exceptions.UsernameExistsFailure) {
        setErrorAlert("Username already exists, please try another.");
      } else if (
        exception.name &&
        exception.name === Exceptions.NoInvitationExistsFailure
      ) {
        setErrorAlert("Error Creating User: " + exception.message);
      } else {
        console.log("Error Registering User: ", exception);
        setErrorAlert("Unknown issue.  Please contact your administrator. ");
      }

      setLoadingOpen(false);
    }
    if (success) {
      //redirect to confirmation page on success
      navigate(`/confirmation/${values.username}/${permissions}`);
    }
  }

  function setErrorAlert(message) {
    setAlertState({ isOpen: true, severity: "error", message });
  }

  function setSuccessAlert(message) {
    setAlertState({ isOpen: true, severity: "success", message });
  }

  const onErrorClose = (event, reason) => {
    console.log("Alert closed:", event);
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ ...alertState, isOpen: false });
  };

  return wasInvited ? (
    <Formik
      initialValues={newUserModel}
      validationSchema={NewUserSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, values, isSubmitting }) => {
        return (
          <Form className={classes.form}>
            {" "}
            <Typography variant="h6" color="textPrimary">
              New SIMPL-Bedside Account Registration
            </Typography>
            <Grid container className={classes.root} spacing={3}>
              <Grid item className={classes.columns} xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Please complete the fields below:
                  </Typography>
                  <Divider />
                  <div className={classes.fields}>
                    <FormikField
                      name="username"
                      label="username"
                      required={true}
                    />
                    <FormikField name="email" label="email" disabled={true} />
                    <FormikField
                      name="password"
                      label="password"
                      type="password"
                      required={true}
                    />
                    <FormikField
                      name="repeatPassword"
                      label="repeat password"
                      type="password"
                      required={true}
                    />
                  </div>
                </Paper>
              </Grid>
              <Grid className={classes.buttonContainer} item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <SnackbarAlert
              open={alertState.isOpen}
              onClose={onErrorClose}
              severity={alertState.severity}
              message={alertState.message}
            />
            <div>
              <Backdrop className={classes.backdrop} open={loadingOpen}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <div className={classes.form}>
      {" "}
      <Typography variant="h6" color="textPrimary">
        404 - Page Not Found
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item className={classes.columns} xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" color="textSecondary">
              The requested invitation does not exist, or has been resolved.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserInvitationResponseForm;
