import { Link } from "react-router-dom";
import { Toolbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { NurseReportFields } from "../../model/ApplicationConstants";

const useStyles = makeStyles((theme) => ({
  footerlogo: {
    maxWidth: "75px",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    padding: "2em",
  },
  link: {
    textDecoration: "none",
    boxShadow: "none",
    justifyText: "center",
    fontSize: "12px",
  },
}));

export function Footer() {
  const classes = useStyles();
  var path = process.env.PUBLIC_URL;
  var simplLogo = "/simpllogonotext.png";

  return (
    <div>
      <p />
      Do you have questions or need technical assistance? Please contact us
      at&nbsp;
      <a href="mailto:help@simpl-bedside.org?subject=SIMPL Bedside Support Request">
        help@simpl-bedside.org
      </a>
      <Toolbar className={classes.footer}>
        <img src={path + simplLogo} alt="logo" className={classes.footerlogo} />
        <Link
          className={classes.link}
          to={{ pathname: "http://simpl.org" }}
          target="_blank"
        >
          {NurseReportFields.simplLinkText}
        </Link>
      </Toolbar>
    </div>
  );
}
