import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";

/**
 * Returns all the cert records for the userId.
 * @param {*} userId
 */
export async function fetchCertRecords(userId) {
  let operation = graphqlOperation(queries.listCertificationRecords, {
    filter: { subjectId: { eq: userId } },
    limit: 100,
  });

  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    throw Exceptions.CertRecordFetchFailure;
  }

  return response;
}

/**
 * Returns all the cert records for the userId in the format needed for table display.
 * @param {*} userId
 */
export async function fetchCertRecordsForTable(userId) {
  let operation = graphqlOperation(customQueries.certRecordsBySubject, {
    subjectId: userId,
    limit: 100,
  });

  let response;
  try {
    response = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    console.log(error);
    throw Exceptions.CertRecordFetchFailure;
  }

  return response;
}

/**
 * Create a new certification record with the following params.
 * @param {*} subjectId - The id of the user being certified.
 * @param {*} procedureId - The id of the procedure the user is certified in.
 * @param {*} isCertified - Whether they are certified or not.
 * @param {*} certificationMethod - Manual or CertificationRule
 */
export async function newCertificationRecord(
  subjectId,
  procedureId,
  isCertified = true,
  certificationMethod = "Manual"
) {
  let exists;
  try {
    exists = await certificationRecordExists(subjectId, procedureId);
  } catch (error) {
    console.log("Certification Record Lookup Failure: ", error);
    throw Exceptions.CertRecordLookupFailure;
  }

  if (!Boolean(exists)) {
    await API.graphql(
      graphqlOperation(mutations.createCertificationRecord, {
        input: {
          subjectId,
          procedureId,
          isCertified,
          certificationMethod,
        },
      })
    );
  } else {
    throw Exceptions.CertRecordExists;
  }
}

/**
 * Determines if a Certification Record exists for the provided User and Procedure combination
 * @param {*} subjectId
 * @param {*} procedureId
 */
export async function certificationRecordExists(subjectId, procedureId) {
  let params = {
    filter: { subjectId: { eq: subjectId }, procedureId: { eq: procedureId } },
  };
  let response = await API.graphql(
    graphqlOperation(queries.listCertificationRecords, params)
  );
  return response.data.listCertificationRecords.items.length > 0;
}

/**
 * For the provided Certification Record, updates the value of hte isCertified field
 * @param {*} isCertified
 * @param {*} certificationId
 */
export async function updateIsCertified(isCertified, certificationId) {
  let certificationValues = {
    id: certificationId,
    isCertified: isCertified,
  };
  try {
    await API.graphql(
      graphqlOperation(mutations.updateCertificationRecord, {
        input: certificationValues,
      })
    );
  } catch (error) {
    console.log("Certification Record Update Error: ", error);
    throw Exceptions.CertRecordUpdateFailure;
  }
}
