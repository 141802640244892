import React from "react";
import Paper from "@mui/material/Paper";
import { performanceValueMap } from "../../../model/ApplicationConstants";

import moment from "moment";
import MaterialTable from "@material-table/core";

export const UserEvaluationResponseTable = ({ evaluationResponses, loading }) => {
  const evalResponseColumns = [
    { title: "Procedure", field: "procedureName" },
    { title: "Rater Name", field: "raterName" },
    { title: "Rating Level", field: "ratingLevel" },
    { title: "Evaluation Date", field: "evaluationDate"},
  ]

  const preparedEvaluationResponses = (evaluationResponses && evaluationResponses.length > 0) ? 
  evaluationResponses.map((response) => {
    return {
      procedureName: response.procedure.name,
      raterName: `${response.rater.lastName}, ${response.rater.firstName}`,
      ratingLevel: performanceValueMap(response.ratingLevel),
      evaluationDate: `${moment(response.evaluationDate).format('MMMM Do, YYYY [at] h:mm a')}`
    }
  }) : evaluationResponses;

  return (
    <Paper>
      <MaterialTable
        isLoading={loading}
        title="Evaluation Responses"
        columns={evalResponseColumns}
        data={preparedEvaluationResponses}
        options={{
        }}
      />
    </Paper>
  );
};
