import { graphqlOperation, API } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";

export async function fetchOrganization(orgId) {
  let operationParams = { orgId };
  let operation = graphqlOperation(
    customQueries.getOrgDetails,
    operationParams
  );
  const response = await API.graphql(operation);
  const organization = response.data.getOrganization;
  return organization;
}

export async function fetchOrganizationData(currentUser) {
  if (
    currentUser === undefined ||
    (!currentUser.isSysAdmin && currentUser.orgID === undefined)
  ) {
    return;
  }

  const parameters = {
    orgId: currentUser.orgCookie ? currentUser.orgCookie : currentUser.orgID,
  };

  try {
    const usersInProgram = await API.graphql(
      graphqlOperation(
        customQueries.listOrgsProgramsMembershipsUsers,
        parameters
      )
    );
    return usersInProgram.data.getOrganization;
  } catch (error) {
    console.error("Error retrieving Organization Data: ", error);
    // Optional chainin is throwing an error, so this is my attempt at conditionally returning
    // the necessary data.
    return (error && error.data && error.data.getOrganization) ? error.data.getOrganization : error;
  }
}

export async function fetchOrgs() {
  let operation = graphqlOperation(queries.listOrganizations);
  let response = await API.graphql(operation);
  return response.data.listOrganizations.items;
}
