import React, { ChangeEvent } from "react";

import { Button } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const FileUpload = (props) => {
  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    props.setFile(event.target.files[0]);
  };

  return (
    <div>
      <Button
        component="label"
        variant="contained"
        color="primary"
        startIcon={<UploadFileIcon />}
      >
        Upload
        <input
          type="file"
          accept=".csv"
          hidden
          onChange={(event) => handleClick(event)}
        />
      </Button>
    </div>
  );
};
