// @flow
import { useEffect, useState } from "react";
import { listPrograms } from "../components/api/ProgramAPI";
import { useCurrentOrganization } from "./Organization";

export type ID = string;

export type Program = {
  id: ID,
  name: string,
  orgID: string,
  description: string,
};
/**
 * Fetch the programs array for the entire organization.
 */
export function usePrograms(): Array<Program> {
  const organization = useCurrentOrganization();
  const [programs, setPrograms] = useState<Array<Program>>([]);

  useEffect(() => {
    async function fetchPrograms() {
      if (organization === undefined || organization.id === undefined) {
        return;
      }
      let fetchedProgs = await listPrograms(organization.id);

      setPrograms(fetchedProgs);
    }

    fetchPrograms();
  }, [organization]);
  return programs;
}
