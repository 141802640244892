import React, { useEffect, useState } from "react";

import { Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import makeStyles from "@mui/styles/makeStyles";

import { UserTable } from "./UserTable";
import { fetchUsers } from "../../util/UserUtil";

import { useCurrentUser } from "../../../model/Users";
import { useCurrentOrganization } from "../../../model/Organization";
import { listPrograms } from "../../api/ProgramAPI";
import { objectIsEmpty } from "../../util/Utility";

import { Link, useNavigate } from "react-router-dom";

const ALL_PROGRAMS_ID = "0";

const allPrograms = {
  name: "All Programs",
  id: ALL_PROGRAMS_ID,
};

const getLastItem = (path) => {
  let pathEnd = path.substring(path.lastIndexOf("/") + 1);
  if (pathEnd === "home") {
    return ALL_PROGRAMS_ID;
  } else {
    return pathEnd;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orgText: {
    float: "right",
  },
  programSelect: {
    float: "left",
    minWidth: "150px",
  },
  linkText: {
    fontSize: ".8rem",
  },
}));

const boldSelectTheme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides : {
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        }
      }
    }
  }
})

const UsersHome = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const organization = useCurrentOrganization();
  const currentUser = useCurrentUser();
  const [users, setUsers] = useState({});
  const [programs, setPrograms] = useState();
  const [programUsers, setProgramUsers] = useState({});
  const [programUsersLoaded, setProgramUsersLoaded] = useState(false);
  const [currentProgram, setCurrentProgram] = useState(allPrograms);
  const [userPermissionsLoaded, setUserPermissionsLoaded] = useState(false);

  const tablePrereqsLoaded = programUsersLoaded && userPermissionsLoaded;

  console.log('cypress un:', process.env.REACT_APP_CYPRESS_UN);
  console.log('cypress pw:', process.env.REACT_APP_CYPRESS_PW);

  useEffect(() => {
    async function getUsers() {
      setProgramUsersLoaded(false);
      let userList = await fetchUsers(organization);
      setUsers(userList);
      setProgramUsers(userList);
      setProgramUsersLoaded(true);
    }
    if (objectIsEmpty(organization)) {
      return;
    }
    getUsers();
  }, [organization]);

  useEffect(() => {
    async function getPrograms() {
      setUserPermissionsLoaded(false);
      let programList = await listPrograms(organization.id);
      if (
        currentUser.cognitoPermissions.isSysAdmin ||
        currentUser.cognitoPermissions.isAdmin
      ) {
        setPrograms([allPrograms, ...programList]);
        setCurrentProgram(allPrograms);
      } else if (currentUser.cognitoPermissions.isProgramAdmin) {
        let paPrograms = programList.filter(
          (program) =>
            currentUser.memberships.items.filter(
              (membership) =>
                membership.role === "ProgramAdmin" &&
                membership.programId === program.id
            ).length > 0
        );
        filterUserList(paPrograms[0].id);
        setPrograms([allPrograms, ...paPrograms]);
        setCurrentProgram(paPrograms[0]);
      }
      setUserPermissionsLoaded(true);
    }
    if (
      objectIsEmpty(currentUser) ||
      objectIsEmpty(organization) ||
      objectIsEmpty(users)
    ) {
      return;
    }
    getPrograms();
  }, [currentUser, organization, users]);

  const filterUserList = (programId) => {
    // Filter the user list by their program memberships
    let fullUsersList = [...users];
    let filteredUsers = fullUsersList.filter((user) => {
      if (user.memberships) {
        let programMembership = user.memberships.find(
          (membership) => membership.programId === programId
        );
        user.isAdmin =
          programMembership && programMembership.role === "ProgramAdmin";
        return programMembership !== undefined;
      }
      return null
    });
    setProgramUsers(filteredUsers);
  };

  const handleProgramChange = (event) => {
    let programId;
    // When event is undefined, the browser back or forward button was clicked
    if (event !== undefined) {
      programId = event.target.value;
    } else {
      programId = getLastItem(navigate.location.pathname);
    }
    const selectedProgram = programs.find(
      (program) => program.id === programId
    );
    // Reset isAdmin flag for all users when "All Programs" is selected
    if (programId === ALL_PROGRAMS_ID) {
      const allUsers = users.map((user) => {
        user.isAdmin = false;
        return user;
      });
      setProgramUsers([...allUsers]);
    } else {
      filterUserList(programId);
    }
    setCurrentProgram(selectedProgram);
    // No need to update the url when forward/back button is pressed
    if (event !== undefined) {
      navigate("/users/home/" + selectedProgram.id);
    }
  };

  return (
    <div>
      <div className={classes.root}>
        {" "}
        <Grid container direction="row" alignItems="flex-start">
          <ThemeProvider theme={boldSelectTheme}>
            <Select
              className={classes.programSelect}
              variant="standard"
              inputProps={{
                name: "programSelect",
                id: "current-program-select",
              }}
              defaultValue={ALL_PROGRAMS_ID}
              value={currentProgram.id}
              onChange={handleProgramChange}
            >
              {programs &&
                programs.map((program) => {
                  return <MenuItem key={`${program.name}-${program.id}`} value={program.id}>{program.name}</MenuItem>;
                })}
            </Select>
          </ThemeProvider>
          <Typography variant="h6" className={classes.orgText}>
            &nbsp;at {organization && organization.title}
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="flex-end">
          <Link to="/users/invite">
            <Button variant="contained" color="primary">
              Invite User
            </Button>
          </Link>
          <Link className={classes.linkText} to="/users/bulkfileupload">
            Bulk Invite Trainees
          </Link>
        </Grid>
      </div>
      <UserTable
        users={tablePrereqsLoaded ? programUsers : undefined}
        isAllPrograms={currentProgram && currentProgram.id === ALL_PROGRAMS_ID}
        tablePrereqsLoaded={tablePrereqsLoaded}
      ></UserTable>
    </div>
  );
};

export default UsersHome;
