import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssignmentIcon from "@mui/icons-material/Assignment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const ProgramProcedureTable = ({ programProcedures = [] }) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {programProcedures.map((programProcedure) => {
        return (
          <ListItem key={programProcedure.id}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={programProcedure.procedure.name}
            ></ListItemText>
            <ListItemText
              secondary={programProcedure.procedure.description}
            ></ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ProgramProcedureTable;
