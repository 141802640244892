import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { FieldArray } from "formik";
import FormikSelect from "../shared/FormikSelect";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ProgramRolesSelectFields } from "../../../model/ApplicationConstants";

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
  dividers: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  programAdd: {
    display: "flex",
    alignItems: "center",
  },
  removeProgram: {
    flexGrow: 0,
    height: "50%",
  },
}));

const ProgramSelector = ({ values, validPrograms }) => {
  const classes = useStyles();
  const availableRoles = ProgramRolesSelectFields.filter(
    (role) => role.value !== "Admin"
  );

  return (
    <FieldArray name="programs">
      {({ push, remove }) => {
        return (
          <div>
            {values.programs.map((p, index) => {
              return (
                <div key={index} className={classes.programAdd}>
                  <Button
                    className={classes.removeProgram}
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <RemoveCircleOutlineIcon />
                  </Button>
                  <div className={classes.fields}>
                    <FormikSelect
                      name={`programs[${index}].program.id`}
                      label="Program"
                      items={validPrograms}
                      value={p.program.id}
                    />
                    <FormikSelect
                      name={`programs[${index}].role`}
                      label="Role"
                      items={availableRoles}
                      value={p.role}
                    />
                  </div>
                  <Divider className={classes.dividers} />
                </div>
              );
            })}

            <Button
              type="button"
              onClick={() => {
                push({ program: "", role: "" });
              }}
            >
              Add Program
            </Button>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default ProgramSelector;
