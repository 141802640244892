import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

export const UserPageErrorAlert = ({ open, message, onClose }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert onClose={onClose} severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
};
