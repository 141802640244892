import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep, isEqual } from 'lodash';
import {
  validateUserInvite,
} from "../shared/DataValidation";
import { useParams, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../model/Users";
import { useCurrentOrganization } from "../../../model/Organization";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import { objectIsEmpty } from "../../util/Utility";
import {
  InviteUserErrors,
  InviteUserFormFields,
  PGYs,
  ProgramRoles,
  ProgramRolesSelectFields,
  UserRoleChoices,
} from "../../../model/ApplicationConstants";
import {
  createInvitation,
  getInviteByEmail,
  InvitationStatus,
} from "../../api/InvitationAPI";
import { LoadingButton } from "@mui/lab";
import SnackbarAlert from "../shared/SnackbarAlert";
import { getUserById } from "../../api/UserAPI";

const roleChoices = [
  UserRoleChoices.User,
  UserRoleChoices.ProgramAdmin,
  UserRoleChoices.Admin,
]

//TODO: Users.js has some of the responsibility
//      For passing props down to this component.
//      We should figure out what is shared and what isn't.
const InviteUserRouter = (props) => {
  const { userID } = useParams();
  const [invitingExistingRater, setInvitingExistingRater] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState();
  const [user, setUser] = useState({});
  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    message: "",
    severity: "success",
  });
  function setErrorAlert(message) {
    setAlertState({ isOpen: true, severity: "error", message });
  }

  function setSuccessAlert(message) {
    setAlertState({ isOpen: true, severity: "success", message });
  }

  const onAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ ...alertState, isOpen: false });
  };

  // Dev Note - Not sure why I have to use useCallback here, but vscode
  // was adament about it.
  const getAndSetUserById = useCallback( async (userId) => {
    setLoadingUserInfo(true);
      try {
        const userData = await getUserById(userId);
        const user = userData?.data?.getUser;
        const userInviteData = await getInviteByEmail(user?.email);
        const invitationForUser = userInviteData?.data?.invitationsByEmail?.items?.[0];
        let newInvitationStatus;
        if(invitationForUser){
          newInvitationStatus = invitationForUser.invitationStatus;
        } else {
          newInvitationStatus = InvitationStatus.NoInvite;
        }
        setInvitationStatus(newInvitationStatus);
        setUser(user);
      } catch (error) {
        console.error(error);
        setErrorAlert(InviteUserErrors.DataFetchError);
      }
      setLoadingUserInfo(false);
  }, [])
  
  useEffect(() => {
    if(userID){
      getAndSetUserById(userID);
    }
  }, [userID, getAndSetUserById])

  useEffect(() => {
    setInvitingExistingRater(!objectIsEmpty(user));
  }, [user]);

  if(loadingUserInfo){
    return <CircularProgress />
  }
  if(user?.userName){
    return <h1>User already exists, no invite needed.</h1>
  }
  if(invitationStatus === InvitationStatus.NoInvite || !invitingExistingRater ){
    return <InviteUser 
    {...props} 
    user={user} 
    invitingExistingRater={invitingExistingRater}
    alertState={alertState}
    setErrorAlert={setErrorAlert}
    setSuccessAlert={setSuccessAlert}
    onAlertClose={onAlertClose}
    /> 
  }
  return <h1>User already invited.</h1>
};

const tooltipStyle = {
  fontSize: "12pt"
}

const RoleTooltip = () => (
  <Box sx={tooltipStyle}>
    <span>
      Institutional Admins can edit all programs,
      procedures and users across their institution.
      <br />
      <br />
      Program Admins can edit procedures and trainees in
      their program(s).
      <br />
      <br /> Users can use the mobile app.
    </span>
  </Box>
)

const PGYTooltip = () => (
  <Box sx={tooltipStyle}>
    <span>
      Please select the trainee's current clinical
      postgraduate year disregarding any research or
      non-clinical time. If in doubt, please consult
      with the trainee's accrediting body such as
      the ACGME.
    </span>
  </Box>
)

export const clearError = (field, errorsObject) => {
  const updatedErrors = cloneDeep(errorsObject);
  if(updatedErrors?.[field]){
    delete updatedErrors[field];
  }
  if(updatedErrors && Object.keys(updatedErrors).length > 0){
    return updatedErrors;
  } else {
    return
  }
}

export const InviteUser = ({ 
  programs, 
  user = null, 
  invitingExistingRater = false,
  alertState,
  setErrorAlert,
  setSuccessAlert,
  onAlertClose,
}) => {
  const currentUser = useCurrentUser();
  const organization = useCurrentOrganization();

  const emptyInvite = {
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    program: "",
    npi: "",
    pgy: "",
    orgID: organization?.id || "",
    programAdminId: currentUser?.id || "",
  }
  
  const [userInvite, setUserInvite] = useState(emptyInvite)
  const [errors, setErrors] = useState();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    if(submitting){
      return;
    }
    event.preventDefault();
    setSubmitting(true);
    try {
      const newErrors = await validateUserInvite({userInvite, invitingExistingRater});
      if(newErrors){
        setSubmitting(false);
        setErrors(newErrors);
        return;
      } else {
        const newUserId = await createInvitation(userInvite);
        if(newUserId){
          setSuccessAlert("Invitation Sent!");
          setUserInvite(emptyInvite);
          setSubmitting(false);
          if (invitingExistingRater) {
            navigate("/users/home");
          }
        }
      }
    } catch (error) {
      console.error(error);
      setErrorAlert(InviteUserErrors.SubmissionError)
      setSubmitting(false);
    }
  }

  const handleFormChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const newFormErrors = clearError(fieldName, errors);
    if(!isEqual(newFormErrors, errors)){
      setErrors(newFormErrors);
    }
    const updatedUserInvite = cloneDeep(userInvite);
    if(fieldValue){
      updatedUserInvite[fieldName] = fieldValue;
    } else {
      updatedUserInvite[fieldName] = "";
    }
    setUserInvite(updatedUserInvite);
  }

  const [availableRoles, setAvailableRoles] = useState(
    ProgramRolesSelectFields
  );

  useEffect(() => {
    setUserInvite(prevState => ({
      ...prevState,
      orgID: organization ? organization.id : "",
      programAdminId: currentUser ? currentUser.id : "",
    }))
  }, [organization, currentUser]);

  useEffect(() => {
    setUserInvite(prevState => ({
      ...prevState,
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      npi: user?.npi,
      userID: user?.id,
    }))
  }, [user])

  const [programSelections, setProgramSelections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.cognitoPermissions === undefined) return;
    let programSelect;
    if (
      currentUser.cognitoPermissions.isSysAdmin ||
      currentUser.cognitoPermissions.isAdmin
    ) {
      programSelect = programs?.map((p) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    } else if (currentUser.cognitoPermissions.isProgramAdmin) {
      let paPrograms = programs?.filter(
        (program) =>
          currentUser.memberships.items.filter(
            (membership) =>
              membership.role === "ProgramAdmin" &&
              membership.programId === program.id
          ).length > 0
      );
      let paAvailableRoles = roleChoices.filter(
        (role) => role.value !== ProgramRoles.Admin
      );
      setAvailableRoles(paAvailableRoles);
      programSelect = paPrograms.map((pap) => {
        return {
          label: pap.name,
          value: pap.id,
        };
      });
    }
    setProgramSelections(programSelect);
  }, [programs, currentUser]);

  return (
    <Box sx={{
      flexGrow: 1,

    }}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Invite User</Typography>
            <Typography variant="caption">* = required field</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errors?.email}
              helperText={errors?.email}
              id={InviteUserFormFields.Email}
              name={InviteUserFormFields.Email}
              label="Email"
              required
              fullWidth
              value={userInvite.email || ""}
              type="email"
              onChange={handleFormChange}
              disabled={!!invitingExistingRater || submitting}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errors?.firstName}
              helperText={errors?.firstName}
              id={InviteUserFormFields.FirstName}
              name={InviteUserFormFields.FirstName}
              label="First Name"
              required
              fullWidth
              value={userInvite.firstName || ""}
              onChange={handleFormChange}
              disabled={submitting}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errors?.lastName}
              helperText={errors?.lastName}
              id={InviteUserFormFields.LastName}
              name={InviteUserFormFields.LastName}
              label="Last Name"
              required
              fullWidth
              value={userInvite.lastName || ""}
              onChange={handleFormChange}
              disabled={submitting}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                error={!!errors?.role}
                id={InviteUserFormFields.Role}
                name={InviteUserFormFields.Role}
                label="Role"
                labelId="role-label"
                value={userInvite.role || ""}
                onChange={handleFormChange}
                disabled={submitting}
                startAdornment={
                  <InputAdornment position="start">
                    <Tooltip title={<RoleTooltip/>}>
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              >
                {
                  availableRoles.map((role) => (
                    <MenuItem key={`${role.label}-${role.value}`} value={role.value}>{role.label}</MenuItem>
                  ))
                }
              </Select>
              <FormHelperText error={!!errors?.role}>{errors?.role}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="program-label">Program</InputLabel>
              <Select
                error={!!errors?.program}
                id={InviteUserFormFields.Program}
                name={InviteUserFormFields.Program}
                label="Program"
                labelId="program-label"
                value={userInvite.program || ""}
                onChange={handleFormChange}
                disabled={submitting}
              >
                {
                  programSelections?.map((program) => (
                    <MenuItem key={`${program?.label}-${program?.value}`} value={program?.value}>{program?.label}</MenuItem>
                  ))
                }
              </Select>
              <FormHelperText error={!!errors?.program}>{errors?.program}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!errors?.npi}
              helperText={errors?.npi}
              id={InviteUserFormFields.NPI}
              name={InviteUserFormFields.NPI}
              label="NPI"
              required={userInvite.role === ProgramRoles.User}
              fullWidth
              value={userInvite.npi || ""}
              onChange={handleFormChange}
              disabled={submitting}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="pgy-label">PGY</InputLabel>
              <Select
                error={!!errors?.pgy}
                id={InviteUserFormFields.PGY}
                name={InviteUserFormFields.PGY}
                label="PGY"
                labelId="pgy-label"
                value={userInvite.pgy || ""}
                onChange={handleFormChange}
                disabled={submitting}
                startAdornment={
                  <InputAdornment position="start">
                    <Tooltip title={<PGYTooltip />}>
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              >
                {
                  PGYs.map((pgy) => (
                    <MenuItem key={`${pgy.label}-${pgy.value}`} value={pgy.value}>{pgy.label}</MenuItem>
                  ))
                }
              </Select>
              <FormHelperText error={!!errors?.pgy}>{errors?.pgy}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={submitting}
              disabled={submitting}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
        <SnackbarAlert
          vertical="bottom"
          open={alertState?.isOpen}
          onClose={onAlertClose}
          severity={alertState?.severity}
          message={alertState?.message}
        />
      </form>
    </Box>
  );
};

export default InviteUserRouter;
