import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Alert from '@mui/material/Alert';

import { fetchUsernameByEmail } from "../api/UserAPI";
import { Exceptions } from "../../model/ApplicationConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    direction: "column",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  gridItem: {
    width: "50%",
  },
  paper: {
    height: "100%",
    minWidth: "300px",
    alignItems: "center",
  },
  grid: {
    alignItems: "center",
    padding: "1em",
  },
  text: {
    width: "50%",
    paddingBottom: "1em",
  },
  resultText: {
    width: "50%",
  },
  title: {
    width: "50",
  },
  recoverButton: {
    width: "50%",
    padding: "1em",
    backgroundColor: "#4C6085",
    "&:hover": {
      backgroundColor: "#002266",
    },
  },
  backButton: {
    width: "20%",
    padding: "1em",
  },
  bottomRow: {
    padding: "1em",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  returnLink: {
    textDecoration: "none",
    paddingTop: "1em",
    color: "#043495",
  },
  headerlogo: {
    maxWidth: "250px",
  },
  footer: {
    display: "flex",
    justifyContent: "start",
    paddingTop: "1em",
  },
  footerlogo: {
    maxWidth: "60px",
  },
}));

export function RetrieveUsername() {
  const classes = useStyles();
  var path = process.env.PUBLIC_URL;
  var bedsideLogo = "/simplbedsidelogo1.png";
  var simplLogo = "/simpllogonotext.png";
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [notValidEmail, setNotValidEmail] = useState("false");
  const [noUsernameForEmail, setNoUsernameForEmail] = useState("false");

  async function onButtonClick() {
    setNotValidEmail(false);
    setNoUsernameForEmail(false);
    setUserName("");
    try {
      var userName = await fetchUsernameByEmail(email);
      if (userName) {
        setUserName(userName);
      } else {
        setNoUsernameForEmail(true);
      }
    } catch (exception) {
      if (exception === Exceptions.UserByEmailFetchFailure) {
        console.log("User fetch failure.");
      } else {
        console.log("User does not exist. ", exception);
        setNotValidEmail(true);
      }
    }
  }

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <img alt="logo" src={path + bedsideLogo} className={classes.headerlogo} />
      <Grid item className={classes.gridItem}>
        <Paper className={classes.paper}>
          <Grid container direction="column" className={classes.grid}>
            <Typography
              variant="h4"
              color="textPrimary"
              className={classes.title}
            >
              Recover Your Username
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
              className={classes.text}
            >
              You can recover your SIMPL Bedside username using the email
              address associated with your account.
            </Typography>
            <TextField
              id="emailAddressField"
              variant="outlined"
              type="email"
              label="Enter your email"
              className={classes.text}
              value={email}
              onChange={handleChange}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.recoverButton}
              onClick={onButtonClick}
            >
              Recover Username
            </Button>
            <p />
            {userName && (
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.resultText}
              >
                Your username is: {userName}
              </Typography>
            )}
            {notValidEmail === true && (
              <Alert severity="error" className={classes.resultText}>
                A user account with this email address does not exist. Please
                check your entry and try again.
              </Alert>
            )}

            {noUsernameForEmail === true && (
              <Alert severity="error" className={classes.resultText}>
                A username with this email address does not exist. Please
                contact{" "}
                <a href="mailto:help@simpl-bedside.org?subject=SIMPL Bedside Support Request">
                  help@simpl-bedside.org
                </a>{" "}
                for additional support.
              </Alert>
            )}

            <Link className={classes.returnLink} to="/">
              Back to Sign In
            </Link>
          </Grid>
        </Paper>
      </Grid>
      <Grid item className={classes.gridItem}>
        <p />
        Do you have questions or need technical assistance? Please contact us
        at&nbsp;
        <a href="mailto:help@simpl-bedside.org?subject=SIMPL Bedside Support Request">
          help@simpl-bedside.org
        </a>
        <Toolbar className={classes.footer}>
          <img
            src={path + simplLogo}
            alt="logo"
            className={classes.footerlogo}
          />
          <Link
            className={classes.link}
            to={{ pathname: "http://simpl.org" }}
            target="_blank"
          >
            SIMPL-Bedside is provided by the Society for Improving Medical
            Professional Learning, a 501c3 nonprofit dedicated to improving the
            quality of medical education.
          </Link>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
