import { Image } from "@aws-amplify/ui-react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerlogo: {
    maxWidth: "250px",
  },
  header: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function Header() {
  const classes = useStyles();
  var path = process.env.PUBLIC_URL;
  var bedsideLogo = "/simplbedsidelogo1.png";

  return (
    <div className={classes.header}>
      <Image
        alt="logo"
        src={path + bedsideLogo}
        className={classes.headerlogo}
      />
    </div>
  );
}
