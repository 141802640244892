import React from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from "react-router-dom";

export function signOut() {
  Auth.signOut()
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
}

//TODO: Get this from constant or something
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  sectionTitle: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  a: {
    color: "inherit",
    textDecoration: "inherit",
  },
}));

function deriveTitleFromPath(pathname) {
  switch (pathname) {
    case "/":
      return "Home";
    default:
      let withoutPath = pathname.slice(1);
      var pathArray = withoutPath.split("/");
      var titleArray = pathArray[0].split("_");
      for (let i = 0; i < titleArray.length; i++) {
        titleArray[i] =
          titleArray[i][0].toUpperCase() + titleArray[i].substr(1);
      }
      return titleArray.join(" ");
  }
}

function MyAppBar({ handleDrawerToggle }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const location = useLocation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    signOut();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large">
          <MenuIcon />
        </IconButton>
        <Typography className={classes.sectionTitle} variant="h6" noWrap>
          {deriveTitleFromPath(location.pathname)}
        </Typography>
        <IconButton
          aria-label="User Menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem>
            <Link className={classes.a} to={`/profile`}>
              My Account
            </Link>
          </MenuItem>
          <MenuItem>
            <a
              className={classes.a}
              href="https://simpl-support.freshdesk.com/support/solutions/70000215976"
              target="_blank"
            >
              Help & Support
            </a>
          </MenuItem>
          <MenuItem onClick={handleLogOut}>Sign Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default MyAppBar;
