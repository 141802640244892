import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { updateMemberships } from "./MembershipAPI";
import { fetchOperationAutoPaginate } from "./APIUtil";
import { Exceptions } from "../../model/ApplicationConstants";
import { checkIfEmailExists } from "../../graphql/customQueries";

class UserAPIError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = Exceptions.UserCreationFailure;
  }
}

export const CognitoGroups = {
  SYSADMINS: "Sysadmins",
  ADMINS: "Admins",
  PROGRAMADMINS: "ProgramAdmins",
  USERS: "Users",
};

/**
 * For the current user, return their Cognito groups
 *
 * @returns groups for current logged in user
 */
async function fetchCognitoGroups() {
  const session = await Auth.currentSession();
  const groups = session.getAccessToken().payload["cognito:groups"];
  return groups;
}

/**
 * Sign the current user out of the application
 *
 * @export
 */
export async function signOutCurrentUser() {
  await Auth.signOut();
}

const computePermissions = (groups) => {
  // TODO - Create exception handling here, user unsucessfully added to groups
  let isSysAdmin = groups.includes(CognitoGroups.SYSADMINS);
  let isAdmin = isSysAdmin || groups.includes(CognitoGroups.ADMINS);
  let isProgramAdmin = isAdmin || groups.includes(CognitoGroups.PROGRAMADMINS);
  let isUser = isAdmin || groups.includes(CognitoGroups.USERS);

  return {
    isSysAdmin,
    isAdmin,
    isProgramAdmin,
    isUser,
  };
};

/**
 * Returns the email address for the current logged-in user
 *
 * @export
 * @returns
 */
export async function fetchCurrentUserEmail() {
  // Get email from cognito
  const cognitoUserInfo = await Auth.currentUserInfo();
  const {
    attributes: { email },
  } = cognitoUserInfo;
  return email;
}

/**
 * Create a user object containing the dyamodb user entry as well as their cognito permission levels
 *
 * @export
 * @param {*} email
 * @returns
 */
export async function constructUserInfo(email) {
  let user = await fetchUserByEmail(email);

  // Get cognito user group and calculate permissions
  const cognitoGroups = await fetchCognitoGroups();
  const permissions = computePermissions(cognitoGroups);
  user.cognitoPermissions = permissions;

  return user;
}

/**
 * Get user entry from dynamodb, by userId
 *
 * @export
 * @param {*} userId
 * @returns
 */
export async function fetchUser(userId) {
  let response = await API.graphql(
    graphqlOperation(queries.getUser, { id: userId })
  );
  return response.data.getUser;
}

export const getUserById = async (
  userId, 
  ) => {
    const userData = await API.graphql({
      query: customQueries.getUserForDetailsPage, 
      variables: { userId: userId }
    })
    return userData;
}

export const userForDetailsPage = async (userId) => {
  return API.graphql({
    query: customQueries.getUserForDetailsPage,
    variables: { userId }
  })
}

export const usersProgramsById = async (userId) => {
  return API.graphql({
    query: queries.listMemberships,
    variables:{
      filter: { userId: { eq: userId }}
    }
  })
}

export const fetchUserDataForDetailsPageById = async (userId, organization, setError) => {
  try {
    const userData = await userForDetailsPage(userId);
    const usersPrograms = await usersProgramsById(userId);
    const thisUserData = userData?.data?.getUser;
    const listOfPrograms = usersPrograms.data?.listMemberships?.items?.length > 0 ? 
      usersPrograms.data.listMemberships.items : [];
    let formattedUserData;
    if(thisUserData){
      formattedUserData = {
        email: thisUserData.email,
        id: thisUserData.id,
        lastLogin: thisUserData.lastLogin,
        name: `${thisUserData.lastName}, ${thisUserData.firstName}`,
        pgy: thisUserData.pgy,
        userName: thisUserData.userName,
        organization: organization,
        programs: listOfPrograms,
      }
    }
    return formattedUserData;
  } catch (error) {
    if(setError){
      setError('fetchUserData error:', error);
    }
    console.error('fetchUserData error:', error);
  }
}

/**
 * Get a user's entry in the dynamodb table from their email address
 *
 * @export
 * @param {*} email
 * @returns
 */
export async function fetchUserByEmail(email) {
  // Get user object from graphql
  var userQueryResponse;
  try {
    userQueryResponse = await API.graphql(
      graphqlOperation(queries.usersByEmail, { email: email })
    );
  } catch (error) {
    console.log("error", error);
    throw Exceptions.UserByEmailFetchFailure;
  }
  const count = userQueryResponse.data.usersByEmail.items.length;
  // If more than one item is returned, there are duplicate entries for the email address in the system.
  if (count > 1) {
    throw Exceptions.UserByEmailCountFailure;
  } else {
    return userQueryResponse.data.usersByEmail.items[0];
  }
}

/**
 * Get a user's username from the dynamodb table by their email address
 *
 * @export
 * @param {*} email
 * @returns userName
 */
export async function fetchUsernameByEmail(email) {
  // Get user object from graphql
  var userQueryResponse;
  try {
    let operation = {
      query: checkIfEmailExists,
      variables: { email, limit: 100 },
      authMode: "API_KEY",
    };
    userQueryResponse = await fetchOperationAutoPaginate(operation);
  } catch (error) {
    console.log("error", error);
    throw Exceptions.UserByEmailFetchFailure;
  }
  const count = userQueryResponse.length;
  // If more than one item is returned, there are duplicate entries for the email address in the system.
  if (count > 1) {
    throw Exceptions.UserByEmailCountFailure;
  } else {
    return userQueryResponse[0].userName;
  }
}

const objectWithoutKey = (object, key) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

/**
 * Take form data and use it to update the user's dynamodb entry
 *
 * @export
 * @param {*} userFormData
 * @returns
 */
export async function updateUser(userFormData) {
  try {
    // remove empty PGY value
    let userData = userFormData.pgy
      ? userFormData
      : objectWithoutKey(userFormData, "pgy");

    // remove empty NPI value (admin)
    userData = userData.npi ? userData : objectWithoutKey(userData, "npi");

    const user = await API.graphql(
      graphqlOperation(mutations.updateUser, { input: userData })
    );
    return user.data.updateUser;
  } catch (error) {
    const message = `Error updating user: ${error}`;
    console.log(message, error);
    throw new UserAPIError(message);
  }
}

/**
 * Update a user's settings in the dynamodb table with the given userId
 * @param {string} userId
 * @param {object} settings - an object containing one or more of the settings found in
 * the UserSettings graphql type.
 */
export async function updateUserSettings(userId, settings) {
  try {
    const user = await API.graphql(
      graphqlOperation(mutations.updateUser, {
        input: { id: userId, settings },
      })
    );
    return user;
  } catch (error) {
    const message = `Error updating user: ${error}`;
    console.log(message, error);
    throw new UserAPIError(message);
  }
}

/**
 * @degraded
 * Take user form data and create a dynamodb user entry.  This function was rendered obselete
 *  by the invitation system.
 * @export
 * @param {*} userFormData
 * @returns
 */
export async function createUser(userFormData) {
  try {
    // remove empty PGY value
    let userData = userFormData.pgy
      ? userFormData
      : objectWithoutKey(userFormData, "pgy");

    // remove empty NPI value (admin)
    userData = userData.npi ? userData : objectWithoutKey(userData, "npi");

    // remove empty program value (admin)
    userData = userData.program
      ? userData
      : objectWithoutKey(userData, "program");

    // remove invitation status value when present
    userData = userData.invitationStatus
      ? objectWithoutKey(userData, "invitationStatus")
      : userData;

    const user = await API.graphql(
      graphqlOperation(mutations.createUser, { input: userData })
    );
    console.log("created User in createUser", user);
    return user.data.createUser;
  } catch (error) {
    const message = `Error creating user: ${error}`;
    console.log("error creating user", error);
    throw new UserAPIError(message);
  }
}

/**
 * @degraded
 * Creates a Cognito user account from form data.  This function was rendered obselete
 *  with the creation of the invitation system.
 *
 * @export
 * @param {*} userFormData
 */
export async function createAccount(userFormData) {
  try {
    await Auth.signUp({
      username: userFormData.username,
      password: userFormData.password,
      attributes: {
        email: userFormData.email,
        "custom:permissions": userFormData.permissions,
      },
    });
  } catch (error) {
    console.log("Cognito Auth Error: ", error);
    if (error.name === "UsernameExistsException") {
      throw Exceptions.UsernameExistsFailure;
    } else if (error.name === Exceptions.NoInvitationExistsFailure) {
      throw error;
    } else {
      throw new Error("Unknown Error");
    }
  }
}

/**
 * Confirms a user's account information in cognito, completing the sign up process
 *
 * @export
 * @param {*} confirmationFormData
 */
export async function confirmAccount(confirmationFormData) {
  try {
    await Auth.confirmSignUp(
      confirmationFormData.userName,
      confirmationFormData.confirmationCode
    );
  } catch (error) {
    console.log("error confirming sign up", error);
    if (error.name === "CodeMismatchException") {
      throw Exceptions.UserConfirmationCodeFailure;
    } else {
      throw Exceptions.UserConfirmationFailure;
    }
  }
}

/**
 * Updates the database values for an existing User
 * @param {*} userFormData
 */
export async function editUser(userFormData) {
  //update cognito info for user (if changed).

  let getUserResponse = await API.graphql(
    graphqlOperation(queries.getUser, { id: userFormData.id })
  );
  let userInfo = getUserResponse.data.getUser;
  var isEmailChanged = false;

  // TODO: handle this in UserForm logic
  if (userInfo.email !== userFormData.email) {
    if (await emailExists(userFormData.email)) {
      return "ERROR: Email address already exists in database.";
    }
    isEmailChanged = true;
  }

  //update email info if needed
  if (isEmailChanged) {
    //TODO: update email in Cognito
  }

  let pgy = userFormData.pgy === "" ? null : userFormData.pgy;
  //Update GraphQL user values
  let userValues = {
    id: userFormData.id,
    userName: userFormData.userName,
    email: userFormData.email,
    npi: parseInt(userFormData.npi, 10),
    pgy: pgy,
    orgID: userFormData.orgID,
    firstName: userFormData.firstName,
    lastName: userFormData.lastName,
  };
  try {
    await API.graphql(
      graphqlOperation(mutations.updateUser, { input: userValues })
    );
  } catch (error) {
    console.log("User update failed: ", error);
  }

  // If memberships exist, make updates where necessary
  if (userFormData.programs.length > 0) {
    await updateMemberships(userFormData);
  }
}

/**
 * Determines whether the specified userName exists in the User list
 * @param {*} userName
 */
export async function userNameExists(userName) {
  if (userName === undefined) return true;
  let params = { userName };
  return await fieldOnUserExists(customQueries.checkIfUserExists, params);
}

/**
 * Determines whether the specified email address exists in the User List
 * @param {*} email
 */
export async function emailExists(email) {
  if (email === undefined) return true;
  let params = { email };
  return await fieldOnUserExists(customQueries.checkIfEmailExists, params);
}

/**
 * Determines whether the specified npi already exists in the User database.
 * @param {*} npi
 */
export async function npiExists(npi) {
  if (npi === undefined) return true;
  let params = { npi };
  return await fieldOnUserExists(customQueries.checkIfNpiExists, params);
}

export async function npiExistsExcludeUser(npi, userId) {
  if (!npi) return true;
  let operation = graphqlOperation(queries.usersByNPI, { npi });
  let response = await API.graphql(operation);
  let items = response.data.usersByNPI.items;
  let npiExistsExcludeUser = items.length > 1 && items[0].id !== userId;
  return npiExistsExcludeUser;
}

/**
 * Generic utility to query a particular field value in the user table
 * @param {*} query
 * @param {*} params
 * @param {*} nextToken
 */
async function fieldOnUserExists(query, params, nextToken = undefined) {
  if (!query) return true;
  if (!params) return true;
  let operationParams = { ...params, nextToken, limit: 10000 };
  let operation = graphqlOperation(query, operationParams);
  let response = await API.graphql(operation);
  let exists = response.data.listUsers.items.length > 0;
  let responseNextToken = response.data.listUsers.nextToken;

  if (exists) {
    return true;
  }

  if (!responseNextToken) {
    return exists;
  } else {
    return fieldOnUserExists(query, params, responseNextToken);
  }
}

/**
 * Adds the Cognito group membership for the User provided.
 * @param {*} username
 * @param {*} group
 */
export async function addToGroup(userName, group) {
  let apiName = "AdminQueries";
  let path = "/addUserToGroup";
  let myInit = {
    body: {
      username: userName,
      groupname: group,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  try {
    let response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    console.log("Unable to add user to group: ", error);
  }
}

/**
 * Removes the Cognito group membership for the User provided.
 * @param {*} username
 * @param {*} group
 */
export async function removeFromGroup(userName, group) {
  let apiName = "AdminQueries";
  let path = "/removeUserFromGroup";
  let myInit = {
    body: {
      username: userName,
      groupname: group,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  try {
    let response = await API.post(apiName, path, myInit);
    return response;
  } catch (error) {
    console.log("Unable to remove user from group: ", error);
  }
}
