import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import { Divider, Grid, Paper } from "@mui/material";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    width: "100%",
  },
  columns: {
    "& > *": {
      padding: theme.spacing(2),
    },
  },
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
  form: {
    paddingLeft: 25,
    paddingTop: 25,
  },
}));

const UserInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.form}>
      <Typography variant="h6" color="textPrimary">
        SIMPL-Bedside New User Information
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item className={classes.columns} xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" color="textSecondary">
              Download the SIMPL-Bedside App{" "}
              <a href="https://apps.apple.com/us/app/id1501358895">Here</a>
            </Typography>
            <Divider />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserInfo;
