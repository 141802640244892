import React from "react";
import MaterialTable from "@material-table/core";
import { Paper } from "@mui/material/";

const columnDefinition = [
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
  { title: "Programs", field: "programString" },
];

const ProcedureTable = ({ procedures = [] }) => {
  return (
    <Paper>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <MaterialTable
        title=" "
        columns={columnDefinition}
        data={procedures}
        options={{
          pageSize: 20,
        }}
      />
    </Paper>
  );
};

export default ProcedureTable;
