import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useParams, useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { Formik, Form } from "formik";
import FormikField from "../shared/FormikField";

import { Divider, Grid, Paper, Button } from "@mui/material";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import SnackbarAlert from "../shared/SnackbarAlert";
import { confirmAccount } from "../../api/UserAPI";
import {
  UserPermissions,
  Exceptions,
} from "../../../model/ApplicationConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    width: "150px",
  },
  columns: {
    "& > *": {
      padding: theme.spacing(2),
    },
  },
  fields: {
    margin: theme.spacing(2),
    flexGrow: 3,
  },
  confText: {
    fontSize: "18pt",
  },
  mainHeader: {
    fontSize: "16pt",
    paddingLeft: 25,
  },
  form: {
    paddingLeft: 50,
    paddingTop: 50,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ConfirmationSchema = Yup.object({
  confirmationCode: Yup.string()
    .min(6, "must be 6 digits")
    .max(6, "must be 6 digits")
    .matches(/^[0-9]+$/, "Only numerical characters allowed."),
});

const UserConfirmationForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const userName = params.username;
  const permission = params.permission;
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [alertState, setAlertState] = useState({
    isOpen: false,
    message: "",
    severity: "",
  });

  const initialValues = {
    userName: userName,
    confirmationCode: "",
  };

  function setErrorAlert(message) {
    setAlertState({ isOpen: true, severity: "error", message });
  }

  function setSuccessAlert(message) {
    setAlertState({ isOpen: true, severity: "success", message });
  }

  const onErrorClose = (event, reason) => {
    console.log("Alert closed:", event);

    let success = alertState.severity === "success";

    setAlertState({ ...alertState, isOpen: false });

    if (success) {
      switch (permission) {
        case UserPermissions.Users:
          navigate("/userinfo");
          break;
        case UserPermissions.ProgramAdmins:
        case UserPermissions.Admins:
        default:
          navigate("/");
      }
    }
  };

  async function onSubmit(values) {
    setLoadingOpen(true);
    try {
      await confirmAccount(values);
      setSuccessAlert("User Account Successfully Confirmed.");
    } catch (exception) {
      if (exception === Exceptions.UserConfirmationCodeFailure) {
        setErrorAlert(
          "Unable to confirm your user account: Invalid Confirmation Code."
        );
      } else {
        setErrorAlert(
          "Unable to confirm your user account.  Please contact your administrator."
        );
      }
      console.log("Error confirming user: ", exception);
    }
    setLoadingOpen(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ConfirmationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isValid, values, isSubmitting }) => {
        return (
          <Form className={classes.form}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.mainHeader}
            >
              SIMPL-Bedside Account Confirmation
            </Typography>
            <Grid container className={classes.root} spacing={3}>
              <Grid item className={classes.columns} xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    className={classes.confText}
                  >
                    Enter the Confirmation Code that was sent to you in a
                    separate email:
                  </Typography>
                  <Divider />
                  <FormikField
                    name="confirmationCode"
                    label="confirmation code"
                    required={true}
                    autoFocus={true}
                  />
                  <Typography variant="body" color="primary">
                    Need help with SIMPL-Beside? Please contact{" "}
                    <a href="mailto:help@simpl.org"> help@simpl.org </a>
                    for questions.
                  </Typography>
                </Paper>
              </Grid>
              <Grid className={classes.buttonContainer} item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <SnackbarAlert
              open={alertState.isOpen}
              onClose={onErrorClose}
              severity={alertState.severity}
              message={alertState.message}
              autoHideDuration={1000}
            />
            <div>
              <Backdrop className={classes.backdrop} open={loadingOpen}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserConfirmationForm;
