import React, { useState, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from '@mui/styles/makeStyles';

import { listProgramProcedures } from "../../api/ProgramProcedureAPI";
import ProgramProcedureTable from "./ProgramProcedureTable";
import { ProgramProceduresUpdate } from "./ProgramProceduresUpdate";
import { useCurrentUser } from "../../../model/Users";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  dialogCloseIcon: {
    float: "right",
  },
  dialogTitleText: {
    float: "left",
  },
}));

export const allowUpdateProcedures = (currentUser, programId) => {
  const isProgramAdmin =
    currentUser.memberships &&
    currentUser.memberships.items.filter(
      (membership) =>
        membership.role === "ProgramAdmin" && membership.programId === programId
    ).length > 0;
  const isAdmin =
    currentUser.cognitoPermissions && currentUser.cognitoPermissions.isAdmin;
  return isAdmin || isProgramAdmin;
};

export const ProgramProcedures = ({ program }) => {
  const currentUser = useCurrentUser();
  const [programProcedures, setProgramProcedures] = useState([]);
  const [addProgramProcedureFormOpen, setAddProgramProcedureFormOpen] =
    useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function fetchProgramProcedures() {
      let currentProgramProcedures;
      try {
        currentProgramProcedures = await listProgramProcedures(program.id);
      } catch (exception) {
        console.log("Program Procedure fetch error: ", exception);
      }
      setProgramProcedures(currentProgramProcedures);
    }
    if (program.id === "") return;
    fetchProgramProcedures();
  }, [program, addProgramProcedureFormOpen]);

  const handleAddProgramProcedureFormOpen = () => {
    setAddProgramProcedureFormOpen(true);
  };
  const handleAddProgramProcedureFormClose = () => {
    setAddProgramProcedureFormOpen(false);
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography component="div" variant="h6">
          Procedure List for the {program.name} Program
        </Typography>
        {allowUpdateProcedures(currentUser, program.id) && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProgramProcedureFormOpen}
          >
            Edit Program Procedures
          </Button>
        )}

        <Dialog
          open={addProgramProcedureFormOpen}
          onClose={handleAddProgramProcedureFormClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <div className={classes.dialogTitleText}>
              Edit {program.name} Procedure List
            </div>
            <div className={classes.dialogCloseIcon}>
              <IconButton
                aria-label="close dialog"
                onClick={handleAddProgramProcedureFormClose}
                size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography component="span" variant="subtitle2">
                Please contact your GME office regarding any changes to this
                procedure list.
              </Typography>
            </DialogContentText>
            <ProgramProceduresUpdate
              programProcedures={programProcedures}
              program={program}
            />
          </DialogContent>
        </Dialog>
      </div>
      <ProgramProcedureTable programProcedures={programProcedures} />
    </React.Fragment>
  );
};
