import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";
import { Link as ReactRouterLink } from "react-router-dom";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex
      alignItems="center"
      direction="column"
      gap=".5rem"
      padding={tokens.space.small}
    >
      <Link as={ReactRouterLink} to="/retrieveusername">
        Forgot Username?
      </Link>
      <Link onClick={toResetPassword}>Forgot Password?</Link>
    </Flex>
  );
}
