import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import DomainIcon from "@mui/icons-material/Domain";
import { Link } from "react-router-dom";

import { useCurrentUser } from "../../model/Users";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  links: theme.links,
}));

export const NavigationDrawerContents = ({ handleClose }) => {
  const classes = useStyles();
  const { cognitoPermissions } = useCurrentUser();

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <Link to="/users/home" className={classes.links}>
          <ListItem button onClick={handleClose} key="Users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </Link>

        <Link to="/procedures" className={classes.links}>
          <ListItem button onClick={handleClose} key="Procedures">
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Procedures" />
          </ListItem>
        </Link>

        <Link to="/programs" className={classes.links}>
          <ListItem button onClick={handleClose} key="Programs">
            <ListItemIcon>
              <DomainIcon />
            </ListItemIcon>
            <ListItemText primary="Programs" />
          </ListItem>
        </Link>

        {cognitoPermissions && cognitoPermissions.isSysAdmin && (
          <Link to="/institutions" className={classes.links}>
            <ListItem button onClick={handleClose} key="Organizations">
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText primary="Institutions" />
            </ListItem>
          </Link>
        )}
        {/* <Link to="/reports" className={classes.links}>
          <ListItem button onClick={handleClose} key="Reports">
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItem>
        </Link> */}
      </List>
      <Divider />
      {/* <Divider />
      <List>
        <Link to="/settings" className={classes.links}>
          <ListItem button onClick={handleClose} key="Settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </Link>
      </List> */}
    </div>
  );
};
