import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useIdleTimer } from "react-idle-timer";

import MyAppBar from "./navigation/MyAppBar";
import { NavigationDrawer } from "./navigation/NavigationDrawer";
import UserSessionExpiredDialog from "./main/shared/UserSessionExpiredDialog";
import Program from "./main/programs/Program";

import {
  Users,
  Procedures,
  Programs,
  Reports,
  Settings,
  Profile,
  Organizations,
} from "./main";

import { Routes, Route, Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
  },
  appbarSpacer: {},
}));

const AdminHome = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    setOpen(true);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div className={classes.root}>
      <MyAppBar handleDrawerToggle={handleDrawerToggle} />
      <NavigationDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes>
          <Route path="/" element={<Navigate to="users/home" replace />} />
          <Route path="users/*" element={<Users />} />
          <Route path="procedures" element={<Procedures />} />
          <Route path="programs" element={<Programs />} />
          <Route path="program/:programId" element={<Program />} />
          <Route path="institutions" element={<Organizations />} />
          <Route path="reports" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="profile" element={<Profile />} />
        </Routes>
      </main>
      <UserSessionExpiredDialog openDialog={open} />
    </div>
  );
};

export default AdminHome;
