import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Paper, Button, Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from '@mui/styles/makeStyles';

import { UserHeader } from "./UserHeader";
import { UserCertificationRecordTable } from "./UserCertificationRecordTable";
import { UserEvaluationResponseTable } from "./UserEvaluationResponseTable";
import { UserPageErrorAlert } from "./UserPageErrorAlert";
import { UserProcedureCertificationTable } from "./UserProcedureCertificationTable";
import { useCurrentOrganization } from "../../../model/Organization";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { API } from "aws-amplify";
import { listCertificationRecordsForDetailsPage, listEvaluationResponsesForDetailsPage } from "../../../graphql/customQueries";
import { fetchUserDataForDetailsPageById } from "../../api/UserAPI";
import { fetchCertRecordsForTable, updateIsCertified } from "../../api/CertRecordAPI";
import { fetchEvalResponsesForTable } from "../../api/EvalResponseAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tableTitle: {
    display: "flex",
    justifyContent: "space-between",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  dialogCloseIcon: {
    float: "right",
  },
  dialogTitleText: {
    float: "left",
  },
}));

/**
 * Displays either the user object passed in, or the userID passed through
 * the url parameter (defined in Users.js)
 *
 * @param initialUser a user object from the api
 * @param userID passed via the url route.
 */
const User = ({ initialUser = undefined }) => {
  const params = useParams();
  const organization = useCurrentOrganization();
  const [userId, setUserId] = useState();
  const [certRecordId, setCertRecordId] = useState();
  const [showCertRecords, setShowCertRecords] = useState(false);
  const [addCertRecordFormOpen, setAddCertRecordFormOpen] = useState(false);

  const [userData, setUserData] = useState(initialUser);
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [certRecords, setCertRecords] = useState();
  const [loadingCertRecords, setLoadingCertRecords] = useState(true);
  const [updatingCertStatus, setUpdatingCertStatus] = useState(false);

  const [evalResponses, setEvalResponses] = useState();
  const [loadingEvalResponses, setLoadingEvalResponses] = useState(true);

  const fetchCertRecords = async (userId) => {
    try {
      const certRecords = await fetchCertRecordsForTable(userId);
      if(certRecords){
        setCertRecords(certRecords);
      }
      setLoadingCertRecords(false);
    } catch(error){
      setError('fetchCertRecords error:', error);
      console.error('fetchCertRecords error:', error);
    }
  }

  const fetchEvalResponses = async (userId) => {
    try {
      const evalResponses = await fetchEvalResponsesForTable(userId);

      if(evalResponses){
        setEvalResponses(evalResponses);
      }
      setLoadingEvalResponses(false);
    } catch(error) {
      setError('fetchEvalResponses error:', error);
      console.error('fetchEvalResponses error:', error);
    }
  }

  const updateUserModel = async (userId) => {
    if(userId){
      const userData = await fetchUserDataForDetailsPageById(userId, organization, setError);
      setLoadingUserData(false);
      setUserData(userData);
      // fetchUserData(userId);
      fetchEvalResponses(userId);
      fetchCertRecords(userId);
    }
  };

  const handleCertClick = async (newValue, targetId) => {
    if(!userId){
      return
    }
    try {
      setUpdatingCertStatus(true)
      await updateIsCertified(newValue, targetId);
      await fetchCertRecords(userId)
      setUpdatingCertStatus(false);
    } catch (error) {
      console.error("Error changing cert status:", error);
    }
  }

  // Respond to changes with the userModel.
  // This is triggered automatically when the initialUser param is passed in.
  useEffect(() => {
    if (!userData) return;
    setUserId(userData.id);
    setShowCertRecords(!!userData.userName);
  }, [userData]);

  // Checks if the userID was passed in via url params.
  useEffect(() => {
    if (params.userID) {
      setUserId(params.userID);
    }
  }, [params]);

  // Main side effect to fetch all data when a user is selected
  // by setting the user's id.
  useEffect(() => {
    if (!userId || typeof userId != "string") {
      return;
    }
    updateUserModel(userId);
  }, [userId]);

  useEffect(() => {
    if(userData && organization && userData.organization !== organization){
      setUserData(prevState => ({
        ...prevState,
        organization: organization,
      }))
    }
  }, [organization, userData])

  const setFilter = (certId) => {
    setCertRecordId(certId);
  };

  const [error, setError] = useState({
    show: false,
    message: "",
  });

  const onErrorClose = () => {
    setError({ show: false, message: "" });
  };

  const classes = useStyles();

  const handleOpenAddCertRecordForm = () => {
    setAddCertRecordFormOpen(true);
  };

  const handleCloseAddCertRecordForm = async () => {
    setAddCertRecordFormOpen(false);
    await updateUserModel(userId);
  };

  return (
    <div className={`${classes.root} User`}>
      <Paper className={classes.paper}>
        <UserHeader
          userModel={userData}
          loading={loadingUserData}
        ></UserHeader>
      </Paper>
      {showCertRecords && (
        <Paper className={classes.paper}>
          <Box>
            <div className={classes.tableTitle}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenAddCertRecordForm}
                disabled={loadingCertRecords}
              >
                + Add
              </Button>
            </div>
            <UserCertificationRecordTable
              certificationRecords={certRecords}
              setFilter={setFilter}
              loading={loadingCertRecords}
              handleCertClick={handleCertClick}
              updatingCertStatus={updatingCertStatus}
            ></UserCertificationRecordTable>
          </Box>{" "}
        </Paper>
      )}
      <Paper className={classes.paper}>
        <Box>
          <UserEvaluationResponseTable
            evaluationResponses={
              certRecordId
                ? evalResponses.filter(
                    (evalResponse) =>
                      evalResponse.certificationRecordId === certRecordId
                  )
                : evalResponses
            }
            loading={loadingEvalResponses}
          ></UserEvaluationResponseTable>
        </Box>{" "}
      </Paper>
      <UserPageErrorAlert
        open={error.show}
        message={error.message}
        onClose={onErrorClose}
      />
      <Dialog
        open={addCertRecordFormOpen}
        onClose={handleCloseAddCertRecordForm}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.dialogTitleText}>
            New Certification Record
          </div>
          <div className={classes.dialogCloseIcon}>
            <IconButton
              aria-label="close dialog"
              onClick={handleCloseAddCertRecordForm}
              size="large">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {(userData && userData.id && !loadingUserData) && (
            <UserProcedureCertificationTable userId={userData.id} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default User;
