import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { signOut } from "./navigation/MyAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    paddingLeft: 25,
    paddingTop: 25,
  },
}));

const UserHome = () => {
  const classes = useStyles();
  return (
    <div className={classes.form}>
      <h1>Please use the mobile application</h1>
      <a onClick={() => signOut()} href="#">Log Out</a>
    </div>
  );
};

export default UserHome;
