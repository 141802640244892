import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikField from "../shared/FormikField";
import Button from "@mui/material/Button";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";

const ProcedureSchema = Yup.object({
  name: Yup.string()
    .min(2, "Too Short")
    .max(100, "Too Long")
    .required("Required"),
  description: Yup.string()
    .max(240, "Too Long")
    .required("Required")
});

const initialValues = {
  name: "",
  description: ""
};

const ProcedureForm = ({ closeForm }) => {
  async function onSubmit(event) {
    const input = {
      name: event.name,
      description: event.description
    };
    try {
      let operation = graphqlOperation(mutations.createProcedure, { input });
      await API.graphql(operation);
      closeForm();
    } catch (error) {
      //TODO: Put the error in the form
      console.error(error);
    }
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={ProcedureSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <FormikField name="name" label="Procedure Name"></FormikField>
              <FormikField name="description" label="Procedure Description" />
              <Button
                variant="contained"
                color="primary"
                disabled={!dirty || !isValid}
                type="submit"
              >
                SUBMIT
              </Button>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default ProcedureForm;
