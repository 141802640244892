import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const ConfirmDialog = (props) => {
  const { title, confirmState, setConfirmState } = props;
  return (
    <Dialog
      open={confirmState.isOpen}
      onClose={() => setConfirmState({ ...confirmState, isOpen: false })}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{confirmState.message}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setConfirmState({ ...confirmState, isOpen: false })}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setConfirmState({
              ...confirmState,
              isOpen: false,
              confirmed: true,
            });
          }}
        >
          {confirmState.confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
