import React from "react";

import MaterialTable from "@material-table/core";

const columnDefinition = [
  {
    title: "First Name",
    field: "firstName",
    validate: (row) => ({
      isValid: (row.firstName || "").length !== 0,
      helperText: "Required",
    }),
  },
  {
    title: "Last Name",
    field: "lastName",
    validate: (row) => ({
      isValid: (row.lastName || "").length !== 0,
      helperText: "Required",
    }),
  },
  {
    title: "Email Address ",
    field: "email",
    validate: (row) => ({
      isValid: (row.email || "").length !== 0,
      helperText: "Required",
    }),
  },
  {
    title: "NPI",
    field: "npi",
    type: "numeric",
    validate: (row) => ({
      isValid: row.npi >= 1000000000 && row.npi <= 1999999999,
      helperText: "Must be between 1000000000 and 1999999999",
    }),
  },
  {
    title: "PGY",
    field: "pgy",
    type: "numeric",
    validate: (row) => ({
      isValid: row.pgy >= 1 && row.pgy <= 12,
      helperText: "Must be a number from 1-12",
    }),
  },
  {
    title: "Organization",
    field: "orgID",
    hidden: true,
  },
  {
    title: "Program",
    field: "program",
    hidden: true,
  },
  {
    title: "Role",
    field: "role",
    hidden: true,
  },
  {
    title: "Program Admin ID",
    field: "programAdminId",
    hidden: true,
  },
  { title: "Rater Status", field: "isRater" },
  {
    title: "Invitation Status",
    field: "invitationStatus",
    editable: "never",
    render: (rowData) => {
      return rowData.invitationStatus === "Not Submitted" ? (
        <p style={{ color: "#E87722", fontWeight: "bold" }}>
          {rowData.invitationStatus}
        </p>
      ) : rowData.invitationStatus === "SUCCESS" ? (
        <p style={{ color: "#008240", fontWeight: "bold" }}>
          {rowData.invitationStatus}
        </p>
      ) : (
        <p style={{ color: "#FF0000", fontWeight: "bold" }}>
          {rowData.invitationStatus}
        </p>
      );
    },
  },
];

export const UserInvitationTable = ({ invitations, setInvitations }) => {
  const DEFAULT_STATUS = "Not Submitted";
  return (
    <MaterialTable
      title="Review Invitation List"
      columns={columnDefinition}
      data={
        invitations && invitations.length > 0
          ? invitations.map((invitation) => {
              return {
                firstName: invitation.firstName,
                lastName: invitation.lastName,
                email: invitation.email,
                npi: invitation.npi,
                pgy: invitation.pgy,
                program: invitation.program,
                role: invitation.role,
                orgID: invitation.orgID,
                programAdminId: invitation.programAdminId,
                isRater: invitation.isRater,
                invitationStatus: invitation.invitationStatus || DEFAULT_STATUS,
              };
            })
          : [{ firstName: "none", lastName: "", email: "" }]
      }
      options={{
        filtering: true,
        pageSize: 10,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              setInvitations([...invitations, newData]);

              resolve();
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataUpdate = [...invitations];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              setInvitations([...dataUpdate]);

              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...invitations];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              setInvitations([...dataDelete]);

              resolve();
            }, 1000);
          }),
      }}
    />
  );
};
