import { Flex, Heading, useTheme } from "@aws-amplify/ui-react";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" alignItems="center">
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        Administrator Login
      </Heading>
    </Flex>
  );
}
