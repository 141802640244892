import { useEffect, useState } from "react";
import { listProcedures } from "../components/api/ProcedureAPI";

export const useProcedures = () => {
  const [procedures, setProcedures] = useState([]);
  useEffect(() => {
    const loadProcedures = async () => {
      const p = await listProcedures();
      const sorted = p.sort((p1, p2) => {
        return p1.name > p2.name ? 1 : -1;
      });
      setProcedures(sorted);
    };
    loadProcedures();
  }, []);
  return procedures;
};
