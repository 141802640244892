// @flow
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';

export type SnackbarAlertProps = {
  open: boolean,
  onClose: any,
  message: string,
  severity: "error" | "warning" | "info" | "success",
  autoHideDuration?: number,
  vertical?: string,
};

const SnackbarAlert = ({
  open,
  onClose,
  message,
  severity,
  autoHideDuration = 6000,
  vertical = "top",
  horizontal = "center",
}: SnackbarAlertProps) => {
  const styles = {
    alert: {
      minWidth: "600px",
      fontWeight: "bold",
    },
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={onClose} severity={severity} style={styles.alert}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
