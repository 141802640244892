import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Field, ErrorMessage } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles(
  {
    required: { color: "red" },
  },
  { name: "MuiFormHelperText" }
);

const MaterialUISelectField = ({
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel variant="standard" required={required}>
        {label}
      </InputLabel>
      <Select
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        variant="standard"
      >
        {children}
      </Select>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

const FormikSelect = ({
  label,
  leadItem,
  items,
  value,
  name,
  required = false,
  className = undefined,
}) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <Field
        required={required}
        className={classes.required}
        name={name}
        as={MaterialUISelectField}
        label={label}
        value={value}
        errorString={<ErrorMessage name={name} />}
      >
        {leadItem !== undefined ? (
          <MenuItem key={leadItem.value} value={leadItem.value}>
            {leadItem.label}
          </MenuItem>
        ) : null}

        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
};

export default FormikSelect;
